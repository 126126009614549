import { useAppSelector } from '@/stores';
import { useMemo } from 'react';

function useLoadingCommon() {
  const {
    listUser: { loading: loading1 },
    totalKindUser: { loading: loading2 },
    updateStatus: { loading: loading3 },
    deleteUser: { loading: loading4 },
    userDetail: { loading: loading5 },
  } = useAppSelector(state => state.user);
  const { isLoading: loading6 } = useAppSelector(state => state.auth);

  const {
    listUser: { loading: loading8 },
    listRoles: { loading: loading9 },
    changeRoleUser: { isLoading: loading7 },
    detailUser: { loading: loading16 },
  } = useAppSelector(state => state.userPermission);

  const {
    listRolePermission: { loading: loading10 },
    listRoles: { loading: loading11 },
    createPermission: { isLoading: loading12 },
    editPermission: { isLoading: loading13 },
    createRole: { isLoading: loading14 },
    deletePermission: { loading: loading15 },
  } = useAppSelector(state => state.roleAndPermission);

  const {
    listTrendingKeyword: { loading: loading17 },
    chartUserActivity: { loading: loading18 },
    chartUserArverage: { loading: loading19 },
    chartMatchingConnection: { loading: loading20 },
    listUserRecentActive: { loading: loading21 },
    listShareMethod: { loading: loading22 },
  } = useAppSelector(state => state.dashboard);

  const {
    listKeyPermission: { loading: loading23 },
  } = useAppSelector(state => state.keyPermission);

  const {
    isLoading: loading24,
    //  error
  } = useAppSelector(state => state.app.upload);

  const listLoading = [
    loading1,
    loading2,
    loading3,
    loading4,
    loading5,
    loading6,
    loading7,
    loading8,
    loading9,
    loading10,
    loading11,
    loading12,
    loading13,
    loading14,
    loading15,
    loading16,
    loading17,
    loading18,
    loading19,
    loading20,
    loading21,
    loading22,
    loading23,
    loading24,
  ];
  const isLoadingCommon = useMemo(() => listLoading.some(item => item), [listLoading]);

  return { isLoadingCommon };
}

export default useLoadingCommon;
