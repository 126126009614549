import { createAsyncThunk } from '@reduxjs/toolkit';
import DASHBOARD_API from './api';

interface actionData {
  dataSubmit: any;
  callback?: () => void;
}

// == used

export const postUserActivityAction = createAsyncThunk<any, actionData>(
  'postUserActivity',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postUserActivity(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const postUserAverageAction = createAsyncThunk<any, actionData>(
  'postUserAverage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postUserAverage(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const postUserAverageSumAction = createAsyncThunk<any, actionData>(
  'postUserAverageSum',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postUserAverageSum(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const postTrendingKeywordAction = createAsyncThunk<any, actionData>(
  'postTrendingKeyword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postTrendingKeyword(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const postMatchingConnectionAction = createAsyncThunk<any, actionData>(
  'postMatchingConnectionAction',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postMatchingConnection(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
export const postUserRecentActiveAction = createAsyncThunk<any, actionData>(
  'postUserRecentActiveAction',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postUserRecentActive(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const postShareMethodAction = createAsyncThunk<any, actionData>(
  'postShareMethod',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DASHBOARD_API.postShareMethod(data.dataSubmit);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
