import { createAsyncThunk } from '@reduxjs/toolkit';
import SETTINGS_API from './settings.api';

export const getListMenuBarAction = createAsyncThunk<any, any>(
  'menu_bar',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SETTINGS_API.getListMenuBarConfig(params);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getListThemesAction = createAsyncThunk<any, any>(
  'get_list_themes',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SETTINGS_API.getListThemes(params);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getDetailThemeAction = createAsyncThunk<any, any>(
  'theme_detail',
  async (id, { rejectWithValue }) => {
    try {
      const res = await SETTINGS_API.detailTheme(id);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const updateThemeAction = createAsyncThunk<any, any>('theme_edit', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.updateTheme(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const deleteThemeAction = createAsyncThunk<any, any>('theme_delete', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.deleteTheme(data?.id);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const createThemeAction = createAsyncThunk<any, any>('theme_create', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.createTheme(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const switchThemesAction = createAsyncThunk<any, any>('switch_themes', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.switchTheme(data?.dataSubmit);
    data?.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const getDetailDJAction = createAsyncThunk<any, any>('dj_detail', async (id, { rejectWithValue }) => {
  try {
    const res = await SETTINGS_API.detailDJ(id);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const createDJAction = createAsyncThunk<any, any>('create_dj', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.createDJ(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const deleteDJAction = createAsyncThunk<any, any>('delete_dj', async (id, { rejectWithValue }) => {
  try {
    const res = await SETTINGS_API.deleteDJ(id);
    window.location.reload();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateDJAction = createAsyncThunk<any, any>('update_dj', async (data, { rejectWithValue }) => {
  try {
    const response = await SETTINGS_API.updateDJ(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const getGenresAction = createAsyncThunk<any>('genre', async (id, { rejectWithValue }) => {
  try {
    const res = await SETTINGS_API.getGenres();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
