import { Pagination as CPagination, PaginationProps } from 'antd';
import React from 'react';
import styled from 'styled-components';

const PaginationStyled = styled(CPagination)<any>`
  display: flex;
  justify-content: flex-end;
  li {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    .ant-pagination-item-link {
      border-radius: 6px;
    }
  }
`;

interface CPaginationProps extends PaginationProps {
  onChangePage?: any;
  props?: any;
  [key: string]: any;
}
const Pagination: React.FC<CPaginationProps> = ({ onChangePage, ...props }) => {
  return (
    <PaginationStyled
      onChange={(value: number) => {
        onChangePage(value);
      }}
      defaultCurrent={1}
      showQuickJumper={false}
      showSizeChanger={false}
      {...props}
    />
  );
};
export default Pagination;
