/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  createPermissionAction,
  createRoleAction,
  deletePermissionAction,
  editPermissionAction,
  getListAction,
  getListRolesAction,
} from '@/stores/screens/role-and-permissions/action';
import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  listRolePermission: {
    data: any[];
    loading: boolean;
  };
  listRoles: {
    data: any[];
    loading: boolean;
  };
  createRole: {
    isLoading: boolean;
    isSuccess: boolean;
  };
  createPermission: {
    isLoading: boolean;
    isSuccess: boolean;
  };
  editPermission: {
    isLoading: boolean;
    isSuccess?: boolean;
  };
  updateStatus: {
    loading: boolean;
  };
  deletePermission: {
    loading: boolean;
    isSuccess: boolean;
  };
}

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  listRolePermission: {
    data: [],
    loading: false,
  },
  listRoles: {
    data: [],
    loading: false,
  },
  createPermission: {
    isLoading: false,
    isSuccess: false,
  },
  createRole: {
    isLoading: false,
    isSuccess: false,
  },
  editPermission: {
    isLoading: false,
    isSuccess: false,
  },
  updateStatus: {
    loading: false,
  },
  deletePermission: {
    loading: false,
    isSuccess: false,
  },
};

const { actions, reducer } = createSlice({
  name: 'role_permission_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // list user
      .addCase(getListAction.pending, (state, action) => {
        state.listRolePermission.loading = true;
      })
      .addCase(getListAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listRolePermission = {
          ...state.listRolePermission,
          data,
          loading: false,
        };
      })
      .addCase(getListAction.rejected, (state, action) => {
        state.listRolePermission.loading = false;
        state.error = action.error;
      })

      // list role
      .addCase(getListRolesAction.pending, (state, action) => {
        state.listRoles.loading = true;
        state.listRoles.data = [];
      })
      .addCase(getListRolesAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listRoles = {
          data: data?.data.filter(item => item.level !== 5) || [],
          loading: false,
        };
      })
      .addCase(getListRolesAction.rejected, (state, action) => {
        state.listRoles.loading = false;
        state.error = action.error;
      })

      // create user
      .addCase(createPermissionAction.pending, (state, action) => {
        state.createPermission.isLoading = true;
        state.createPermission.isSuccess = false;
      })
      .addCase(createPermissionAction.fulfilled, (state, action) => {
        state.createPermission.isLoading = false;
        state.createPermission.isSuccess = true;
      })
      .addCase(createPermissionAction.rejected, (state, action) => {
        state.createPermission.isLoading = false;
        state.createPermission.isSuccess = false;
        state.error = action.error;
      })

      // edit permission role
      .addCase(editPermissionAction.pending, state => {
        state.editPermission.isLoading = true;
        state.editPermission.isSuccess = false;
      })
      .addCase(editPermissionAction.fulfilled, state => {
        state.editPermission.isLoading = false;
        state.editPermission.isSuccess = true;
      })
      .addCase(editPermissionAction.rejected, (state, action) => {
        state.editPermission.isLoading = false;
        state.editPermission.isSuccess = false;
        state.error = action.error;
      })

      // ==== unused

      // create role
      .addCase(createRoleAction.pending, (state, action) => {
        state.createRole.isLoading = true;
        state.createRole.isSuccess = false;
      })
      .addCase(createRoleAction.fulfilled, (state, action) => {
        state.createRole.isLoading = false;
        state.createRole.isSuccess = true;
      })
      .addCase(createRoleAction.rejected, (state, action) => {
        state.createRole.isLoading = false;
        state.createRole.isSuccess = false;
        state.error = action.error;
      })

      // delete  user
      .addCase(deletePermissionAction.pending, state => {
        state.deletePermission.loading = true;
        state.deletePermission.isSuccess = false;
      })
      .addCase(deletePermissionAction.fulfilled, (state, action) => {
        state.deletePermission.loading = false;
        state.deletePermission.isSuccess = true;
      })
      .addCase(deletePermissionAction.rejected, (state, action) => {
        state.deletePermission.loading = false;
        state.deletePermission.isSuccess = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
