import { Editor } from '@tinymce/tinymce-react';
import { FieldInputProps, FormikErrors, FormikTouched } from 'formik';
import React, { ReactNode } from 'react';

interface CCkeditorProps {
  initialValue?: any;
  props?: any;
  children?: ReactNode;
  propsCkeditor?: any;
  label?: string | ReactNode | undefined;
  field: FieldInputProps<any>;
  touched?: boolean | FormikTouched<any> | Array<FormikTouched<any>>;
  error?: string | string[] | FormikErrors<any> | Array<FormikErrors<any>>;
  disabled?: boolean;
  setFieldValue: (field: any, value: any) => any;
  [key: string]: any;
  propsConfig?: any;
}

const Timyce: React.FC<CCkeditorProps> = ({
  initialValue,
  children,
  field,
  disabled,
  error,
  label,
  touched,
  propsCkeditor,
  setFieldValue,
  propsConfig,
  ...props
}) => {
  const handleEditorChange = (content, editor) => {
    setFieldValue(field.name, content);
  };

  return (
    <Editor
      initialValue={initialValue}
      init={{
        height: 300,
        menubar: 'file edit insert view format table tools help',
        plugins: [
          'advcode advlist advtable anchor autocorrect autolink autosave casechange charmap checklist codesample directionality editimage emoticons export footnotes formatpainter help image insertdatetime link linkchecker lists media mediaembed mergetags nonbreaking pagebreak permanentpen powerpaste searchreplace table tableofcontents tinymcespellchecker typography visualblocks visualchars wordcount',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor forecolor fontfamily fontsize | alignleft aligncenter ' +
          'alignright alignjustify | link image |',
      }}
      onEditorChange={handleEditorChange}
      {...props}
    />
  );
};
export default Timyce;
