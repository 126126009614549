import { createAsyncThunk } from '@reduxjs/toolkit';
import USER_API from './users.api';
import { LOCAL_STORAGE_KEY } from '@/constants';
export const getListUserAction = createAsyncThunk<any, any>('user', async (data, { rejectWithValue }) => {
  try {
    const res = await USER_API.getListUser({ ...data, application: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) });
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getTotalKindUserAction = createAsyncThunk<any>('userTotalKind', async () => {
  try {
    const res = await USER_API.getTotalKindUser({ application: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) });
    return res;
  } catch (err: any) {}
});

export const updateStatusUserAction = createAsyncThunk<any, any>(
  'update_status_user',
  async (data, { rejectWithValue }) => {
    try {
      const response = await USER_API.changeStatus(data?.id, data?.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteUserAction = createAsyncThunk<any, any>('delete_user', async (data, { rejectWithValue }) => {
  try {
    const res = await USER_API.deleteUser(data.id);
    data.callback();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getDetailUserAction = createAsyncThunk<any, any>('user_detail', async (data, { rejectWithValue }) => {
  try {
    const res = await USER_API.detailUser(data.id, data.tab, {
      ...data?.params,
      application: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID),
    });
    return { ...res, tab: data.tab };
  } catch (err: any) {
    data?.callbackFailed();
    return rejectWithValue(err);
  }
});

// ==

export const createUserAction = createAsyncThunk<any, any>('create_user', async (data, { rejectWithValue }) => {
  try {
    const response = await USER_API.createUser(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const updateUserAction = createAsyncThunk<any, any>('update_user', async (data, { rejectWithValue }) => {
  try {
    const response = await USER_API.updateUser(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const getUserVotedListAction = createAsyncThunk<any, any>('user_voted_list', async (id, { rejectWithValue }) => {
  try {
    const res = await USER_API.getUserVotedList(id);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
