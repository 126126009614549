/* eslint-disable @typescript-eslint/restrict-plus-operands */
import {
  postMatchingConnectionAction,
  postShareMethodAction,
  postTrendingKeywordAction,
  postUserActivityAction,
  postUserAverageAction,
  postUserAverageSumAction,
  postUserRecentActiveAction,
} from '@/stores/screens/dashboard/action';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment-timezone';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  state_date: any;
  end_date: any;
  chartUserActivity: {
    data: any[];
    total: any;
    loading: boolean;
  };
  chartUserArverage: {
    data: any[];
    total: any[];
    loading: boolean;
  };
  listTrendingKeyword: {
    data: any[];
    total: any;
    loading: boolean;
  };
  chartMatchingConnection: {
    data: any[];
    total: any[];
    loading: boolean;
  };
  listUserRecentActive: {
    data: any[];
    total: any;
    loading: boolean;
  };
  listShareMethod: {
    data: any[];
    total: any;
    loading: boolean;
  };
}

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  state_date: null,
  end_date: null,
  chartUserActivity: {
    data: [],
    total: null,
    loading: false,
  },
  chartUserArverage: {
    data: [],
    total: [],
    loading: false,
  },
  listTrendingKeyword: {
    data: [],
    total: null,
    loading: false,
  },
  chartMatchingConnection: {
    data: [],
    total: [],
    loading: false,
  },
  listUserRecentActive: {
    data: [],
    total: null,
    loading: false,
  },
  listShareMethod: {
    data: [],
    total: null,
    loading: false,
  },
};

const { actions, reducer } = createSlice({
  name: 'dashboard_slice',
  initialState,
  reducers: {
    changeDateActions(state, action) {
      const { end_date, start_date } = action.payload;
      state.state_date = start_date;
      state.end_date = end_date;
    },
  },
  extraReducers: builder => {
    builder
      // chart user activity
      .addCase(postUserActivityAction.pending, (state, action) => {
        state.chartUserActivity.loading = true;
      })
      .addCase(postUserActivityAction.fulfilled, (state, action) => {
        const { chartData, total } = action.payload;
        const convertChartData = chartData.map(data => ({
          date: moment(data.date).format('DD MMM'),
          value: Number(data.value),
        }));
        state.chartUserActivity = {
          data: convertChartData,
          total,
          loading: false,
        };
      })
      .addCase(postUserActivityAction.rejected, (state, action) => {
        state.chartUserActivity.loading = false;
        state.error = action.error;
      })

      // chart user average
      .addCase(postUserAverageAction.pending, (state, action) => {
        state.chartUserArverage.loading = true;
      })
      .addCase(postUserAverageAction.fulfilled, (state, action) => {
        const { chartData } = action.payload;
        const convertChartData = chartData.map(data => ({
          date: moment(data.date).format('DD MMM'),
          value: Number(data.value),
        }));

        state.chartUserArverage = {
          ...state.chartUserArverage,
          data: convertChartData,
          loading: false,
        };
      })
      .addCase(postUserAverageAction.rejected, (state, action) => {
        state.chartUserArverage.loading = false;
        state.error = action.error;
      })

      // list user average sum
      .addCase(postUserAverageSumAction.pending, (state, action) => {
        state.chartUserArverage.loading = true;
      })
      .addCase(postUserAverageSumAction.fulfilled, (state, action) => {
        const { total } = action.payload;
        state.chartUserArverage = {
          ...state.chartUserArverage,
          total,
          loading: false,
        };
      })
      .addCase(postUserAverageSumAction.rejected, (state, action) => {
        state.chartUserArverage.loading = false;
        state.error = action.error;
      })

      // list trending keyword
      .addCase(postTrendingKeywordAction.pending, (state, action) => {
        state.listTrendingKeyword.loading = true;
      })
      .addCase(postTrendingKeywordAction.fulfilled, (state, action) => {
        state.listTrendingKeyword = {
          data: action.payload.filter(item => item.keyword !== ''),
          total: action.payload.filter(item => item.keyword !== '')?.length,
          loading: false,
        };
      })
      .addCase(postTrendingKeywordAction.rejected, (state, action) => {
        state.listTrendingKeyword.loading = false;
        state.error = action.error;
      })

      // chart matching connection
      .addCase(postMatchingConnectionAction.pending, (state, action) => {
        state.chartMatchingConnection.loading = true;
      })
      .addCase(postMatchingConnectionAction.fulfilled, (state, action) => {
        const { chartData, total } = action.payload;
        const convertChartData = chartData.map(data => ({
          date: moment(data.date).format('DD MMM'),
          value: Number(data.value),
        }));

        state.chartMatchingConnection = {
          ...state.chartMatchingConnection,
          data: convertChartData,
          total,
          loading: false,
        };
      })
      .addCase(postMatchingConnectionAction.rejected, (state, action) => {
        state.chartMatchingConnection.loading = false;
        state.error = action.error;
      })

      // list user recent active
      .addCase(postUserRecentActiveAction.pending, (state, action) => {
        state.listUserRecentActive.loading = true;
      })
      .addCase(postUserRecentActiveAction.fulfilled, (state, action) => {
        state.listUserRecentActive = {
          data: action.payload,
          total: action.payload.filter(item => item.keyword !== '')?.length,
          loading: false,
        };
      })
      .addCase(postUserRecentActiveAction.rejected, (state, action) => {
        state.listUserRecentActive.loading = false;
        state.error = action.error;
      })

      // list share method
      .addCase(postShareMethodAction.pending, (state, action) => {
        state.listShareMethod.loading = true;
      })
      .addCase(postShareMethodAction.fulfilled, (state, action) => {
        state.listShareMethod = {
          data: action.payload.filter(item => item.keyword !== ''),
          total: action.payload.filter(item => item.keyword !== '')?.length,
          loading: false,
        };
      })
      .addCase(postShareMethodAction.rejected, (state, action) => {
        state.listShareMethod.loading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
export const { changeDateActions } = actions;
