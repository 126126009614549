import React from 'react';
import type { RouteObject } from 'react-router-dom';

import { PrivateLayout } from '@/layouts';
import { ROUTES } from '@/constants/routes';
import { LOCAL_STORAGE_KEY, ROLE_LEVEL } from '@/constants';

const SelectAppScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.SelectAppScreen })),
);

const CategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CategoryScreen })),
);

const DetailCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailCategoryScreen })),
);

const CreateCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateCategoryScreen })),
);

const EditCategoryScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditCategoryScreen })),
);

// const SettingsPreferencesScreen = React.lazy(
//   async () => await import('@/screens/privateScreens').then(module => ({ default: module.SettingsPreferencesScreen })),
// );

const SettingsThemesScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.SettingsThemesScreen })),
);

const DetailThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailThemeScreen })),
);

const EditThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditThemeScreen })),
);

const CreateThemeScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateThemeScreen })),
);

const NotFoundScreen = React.lazy(
  async () => await import('@/screens/NotFound').then(module => ({ default: module.NotFound })),
);

// == USER ==
const UsersScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.UsersScreen })),
);

const DetailUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.DetailUserScreen })),
);

const CreateUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.CreateUserScreen })),
);

const EditUserScreen = React.lazy(
  async () => await import('@/screens/privateScreens').then(module => ({ default: module.EditUserScreen })),
);

const PermissionScreen = React.lazy(
  async () => await import('@/screens/privateScreens/users/permissions').then(module => ({ default: module.default })),
);

const RoleAndPermissonScreen = React.lazy(
  async () =>
    await import('@/screens/privateScreens/users/roleAndPermisson').then(module => ({ default: module.default })),
);

// == DASHBOARD ==
const DashboardScreen = React.lazy(
  async () => await import('@/screens/privateScreens/dashboard').then(module => ({ default: module.default })),
);

// == SETTING PAGES ==

const PagesScreen = React.lazy(
  async () => await import('@/screens/privateScreens/settings/pages').then(module => ({ default: module.default })),
);

const PageCreateScreen = React.lazy(
  async () =>
    await import('@/screens/privateScreens/settings/pages/create').then(module => ({ default: module.default })),
);

const PageCloneScreen = React.lazy(
  async () =>
    await import('@/screens/privateScreens/settings/pages/clone').then(module => ({ default: module.default })),
);

const PageEditScreen = React.lazy(
  async () =>
    await import('@/screens/privateScreens/settings/pages/edit').then(module => ({ default: module.default })),
);

export const CATEGORY_SUB_ROUTES = [ROUTES.CATEGORY.DETAIL, ROUTES.CATEGORY.CREATE, ROUTES.CATEGORY.EDIT];

export const USER_SUB_ROUTES = [ROUTES.USER.DETAIL, ROUTES.USER.CREATE, ROUTES.USER.EDIT];

export const USER_SUB_2_ROUTES = [
  ROUTES.USER.PERMISSIONS.DETAIL,
  ROUTES.USER.PERMISSIONS.CREATE,
  ROUTES.USER.PERMISSIONS.EDIT,
];

export const THEMES_SUB_ROUTES = [
  ROUTES.SETTINGS.THEMES.CREATE,
  ROUTES.SETTINGS.THEMES.EDIT,
  ROUTES.SETTINGS.THEMES.DETAIL,
];

export const THEMES_SUB_2_ROUTES = [ROUTES.SETTINGS.PREFERENCES];

export const THEMES_SUB_3_ROUTES = [
  ROUTES.SETTINGS.PAGES.CREATE,
  ROUTES.SETTINGS.PAGES.CLONE,
  ROUTES.SETTINGS.PAGES.EDIT,
];

const defineListRoutes = () => {
  const ROLE = Number(localStorage.getItem(LOCAL_STORAGE_KEY.ROLE_ADMIN));

  const commonRoutes = [
    // == DASHBOARD
    { path: ROUTES.DASHBOARD.MAIN, element: <DashboardScreen /> },

    // == CATEGORY ==
    { path: ROUTES.CATEGORY.LIST, element: <CategoryScreen /> },
    { path: ROUTES.CATEGORY.CREATE, element: <CreateCategoryScreen /> },
    { path: `${ROUTES.CATEGORY.DETAIL}/:id`, element: <DetailCategoryScreen /> },
    { path: `${ROUTES.CATEGORY.EDIT}/:id`, element: <EditCategoryScreen /> },

    // == USER ==
    { path: ROUTES.USER.LIST, element: <UsersScreen /> },
    { path: `${ROUTES.USER.DETAIL}/:id`, element: <DetailUserScreen /> },
    { path: ROUTES.USER.CREATE, element: <CreateUserScreen /> },
    { path: `${ROUTES.USER.EDIT}/:id`, element: <EditUserScreen /> },

    // == SETTINGS/PAGES
    { path: ROUTES.SETTINGS.PAGES.LIST, element: <PagesScreen /> },
    { path: ROUTES.SETTINGS.PAGES.CREATE, element: <PageCreateScreen /> },
    { path: `${ROUTES.SETTINGS.PAGES.CLONE}/:id`, element: <PageCloneScreen /> },
    { path: `${ROUTES.SETTINGS.PAGES.EDIT}/:id`, element: <PageEditScreen /> },
  ];

  const ROUTES_ALL = [
    ...commonRoutes,

    // { path: '/admin/settings/preferences', element: <SettingsPreferencesScreen /> },
    { path: ROUTES.SETTINGS.THEMES.LIST, element: <SettingsThemesScreen /> },
    { path: `${ROUTES.SETTINGS.THEMES.DETAIL}/:id`, element: <DetailThemeScreen /> },
    { path: `${ROUTES.SETTINGS.THEMES.EDIT}/:id`, element: <EditThemeScreen /> },
    { path: ROUTES.SETTINGS.THEMES.CREATE, element: <CreateThemeScreen /> },

    // == USER/PERMISSION
    { path: ROUTES.USER.PERMISSIONS.LIST, element: <PermissionScreen /> },

    // == USER/ROLE AND PERMISSION
    ROLE === ROLE_LEVEL.SUPER_ADMIN
      ? {
          path: ROUTES.USER.ROLE_AND_PERMISSION.LIST,
          element: <RoleAndPermissonScreen />,
        }
      : {},
  ];

  const ROUTES_MODERATOR = [
    ...commonRoutes,

    // { path: '/admin/settings/preferences', element: <SettingsPreferencesScreen /> },
    { path: ROUTES.SETTINGS.THEMES.LIST, element: <SettingsThemesScreen /> },
    { path: `${ROUTES.SETTINGS.THEMES.DETAIL}/:id`, element: <DetailThemeScreen /> },
    { path: `${ROUTES.SETTINGS.THEMES.EDIT}/:id`, element: <EditThemeScreen /> },
    { path: ROUTES.SETTINGS.THEMES.CREATE, element: <CreateThemeScreen /> },

    // == USER/PERMISSION
    { path: ROUTES.USER.PERMISSIONS.LIST, element: <PermissionScreen /> },
  ];

  const ROUTES_TRANSLATOR = [
    ...commonRoutes,
    { path: ROUTES.SETTINGS.THEMES.LIST, element: <SettingsThemesScreen /> },

    // == USER/PERMISSION
    { path: ROUTES.USER.PERMISSIONS.LIST, element: <PermissionScreen /> },
  ];

  const ROUTES_CONTENT_ADMIN = [
    // == CATEGORY ==
    { path: ROUTES.CATEGORY.LIST, element: <CategoryScreen /> },
    { path: `${ROUTES.CATEGORY.DETAIL}/:id`, element: <DetailCategoryScreen /> },

    { path: ROUTES.SETTINGS.THEMES.LIST, element: <SettingsThemesScreen /> },

    // == USER/PERMISSION
    { path: ROUTES.USER.PERMISSIONS.LIST, element: <PermissionScreen /> },
  ];

  const roleRoutes = {
    [ROLE_LEVEL.ADMINISTRATOR]: ROUTES_ALL,
    [ROLE_LEVEL.SUPER_ADMIN]: ROUTES_ALL,
    [ROLE_LEVEL.MODERATOR]: ROUTES_MODERATOR,
    [ROLE_LEVEL.TRANSLATOR]: ROUTES_TRANSLATOR,
    [ROLE_LEVEL.CONTENT_ADMIN]: ROUTES_CONTENT_ADMIN,
  };

  return roleRoutes[ROLE] || ROUTES_ALL;
};

const _privateRoutes: RouteObject[] = [
  {
    element: <PrivateLayout />,
    children: [
      ...defineListRoutes(),
      // == SELECT PLATFORM
      { path: ROUTES.SELECT_APP, element: <SelectAppScreen /> },
      // == NOT_FOUND
      { element: <NotFoundScreen />, path: '*' },
    ],
  },
];

export default _privateRoutes;
