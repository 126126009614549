import { getListKeyPermissionAction } from '@/stores/screens/key-permission/action';
import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  listKeyPermission: { data: any[]; loading: boolean };
}

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  listKeyPermission: { data: [], loading: false },
};

const { actions, reducer } = createSlice({
  name: 'key_permission_slice',
  initialState,
  reducers: {
    clearListKeyPermisstion(state) {
      state.listKeyPermission.data = [];
    },
  },
  extraReducers: builder => {
    builder
      // get list key permission
      .addCase(getListKeyPermissionAction.pending, (state, action) => {
        state.listKeyPermission.loading = true;
      })
      .addCase(getListKeyPermissionAction.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.listKeyPermission = {
          data: data.map(item => item.key),
          loading: false,
        };
      })
      .addCase(getListKeyPermissionAction.rejected, (state, action) => {
        state.listKeyPermission.loading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
export const { clearListKeyPermisstion } = actions;
