import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const USER_API = {
  getListUser: async params => {
    const response = await new AxiosClient().get('/admin/user/list-organization', { params });
    return response;
  },

  getTotalKindUser: async params => {
    const response = await new AxiosClient().get('/admin/user/list-organization/total', { params });
    return response;
  },

  getRole: async () => {
    const response = await new AxiosClient().get('/admin/roles');
    return response;
  },

  createUser: async params => {
    const response = await new AxiosClient().post('/admin/user', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  changeRole: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/application-admin/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  changeStatus: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/user/action/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  detailUser: async (id: number | string) => {
    const response = await new AxiosClient().get(`/admin/user/${id}/application/detail`);
    const data = get(response, 'data', null);
    return data;
  },

  deleteUser: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/user/${id}`);
    return response;
  },
};

export default USER_API;
