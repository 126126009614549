import { createAsyncThunk } from '@reduxjs/toolkit';
import PAGES_API from './api';

interface actionData {
  params: any;
  callback?: () => void;
}

interface actionDetail {
  id: any;
  callback?: () => void;
}
interface actionMasterPage {
  flag: any;
  callback?: () => void;
}

export const getListPagesAction = createAsyncThunk<any, actionData>(
  'getListPages',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PAGES_API.getListPages(data.params);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const getDetailPageAction = createAsyncThunk<any, actionDetail>(
  'getDetailPage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PAGES_API.getDetailPage(data.id);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const getMasterPageAction = createAsyncThunk<any, actionMasterPage>(
  'getMasterPage',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PAGES_API.getMasterPage(data.flag);
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
