import { AxiosClient } from '@/configs/axios/axios';

const PAGES_API = {
  getListPages: async (params: any) => {
    const response = await new AxiosClient().get('page', { params });
    return response;
  },
  getMasterPage: async (flag: number) => {
    const response = await new AxiosClient().get(`page/master?flag=${flag}`);
    return response;
  },
  getDetailPage: async (id: string | number) => {
    const response = await new AxiosClient().get(`page/${id}`);
    return response;
  },
};

export default PAGES_API;
