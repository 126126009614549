/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { DATA_SORT_BY_1, LIST_TAB_ACTIVE } from '@/constants';
import {
  getListRolesAction,
  getListUserAction,
  createUserAction,
  changeRoleUserAction,
  getTotalKindUserAction,
  updateStatusUserAction,
  deleteUserAction,
  getDetailUserAction,
} from '@/stores/screens/user-permissions/action';
import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  isLoading: boolean;
  error: any;
  listUser: {
    data: any[];
    meta: any;
    loading: boolean;
    dataSortBy: any;
  };
  totalKindUser: {
    loading: boolean;
    data: any[];
  };
  listRoles: {
    data: any[];
    loading: boolean;
  };
  createUser: {
    isLoading: boolean;
    isSuccess: boolean;
  };
  changeRoleUser: {
    isLoading: boolean;
    isSuccess?: boolean;
  };
  updateStatus: {
    loading: boolean;
  };
  deleteUser: {
    loading: boolean;
  };
  detailUser: {
    loading: boolean;
    data: any;
  };
}

const initialState: initialStateProps = {
  isLoading: false,
  error: null,
  listUser: {
    data: [],
    meta: undefined,
    loading: false,
    dataSortBy: DATA_SORT_BY_1,
  },
  totalKindUser: {
    data: LIST_TAB_ACTIVE,
    loading: false,
  },
  listRoles: {
    data: [],
    loading: false,
  },
  createUser: {
    isLoading: false,
    isSuccess: false,
  },
  changeRoleUser: {
    isLoading: false,
    isSuccess: false,
  },
  updateStatus: {
    loading: false,
  },
  deleteUser: {
    loading: false,
  },
  detailUser: {
    loading: false,
    data: undefined,
  },
};

const { actions, reducer } = createSlice({
  name: 'users_permission_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // list user
      .addCase(getListUserAction.pending, (state, action) => {
        state.listUser.loading = true;
      })
      .addCase(getListUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listUser = {
          ...state.listUser,
          data: data.item,
          meta: data.totalItem,
          loading: false,
        };
      })
      .addCase(getListUserAction.rejected, (state, action) => {
        state.listUser.loading = false;
        state.error = action.error;
      })

      // total kind user
      .addCase(getTotalKindUserAction.pending, (state, action) => {
        state.totalKindUser.loading = true;
      })
      .addCase(getTotalKindUserAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.totalKindUser.loading = false;
        state.totalKindUser.data = [
          { name: 'Active', value: 1, members: data.active },
          { name: 'Deactive', value: 2, members: data.deactived },
          { name: 'Blocked', value: 3, members: data.blocked },
        ];
      })
      .addCase(getTotalKindUserAction.rejected, (state, action) => {
        state.totalKindUser.loading = false;
        state.error = action.error;
      })

      // list role
      .addCase(getListRolesAction.pending, (state, action) => {
        state.listRoles.loading = true;
        state.listRoles.data = [];
      })
      .addCase(getListRolesAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.listRoles = {
          data: data?.data.filter(item => item.level !== 5) || [],
          loading: false,
        };
      })
      .addCase(getListRolesAction.rejected, (state, action) => {
        state.listRoles.loading = false;
        state.error = action.error;
      })

      // create user
      .addCase(createUserAction.pending, (state, action) => {
        state.createUser.isLoading = true;
        state.createUser.isSuccess = false;
      })
      .addCase(createUserAction.fulfilled, (state, action) => {
        state.createUser.isLoading = false;
        state.createUser.isSuccess = true;
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.createUser.isLoading = false;
        state.createUser.isSuccess = false;
        state.error = action.error;
      })

      // change role
      .addCase(changeRoleUserAction.pending, state => {
        state.changeRoleUser.isLoading = true;
        state.changeRoleUser.isSuccess = false;
      })
      .addCase(changeRoleUserAction.fulfilled, state => {
        state.changeRoleUser.isLoading = false;
        state.changeRoleUser.isSuccess = true;
      })
      .addCase(changeRoleUserAction.rejected, (state, action) => {
        state.changeRoleUser.isLoading = false;
        state.changeRoleUser.isSuccess = false;
        state.error = action.error;
      })

      // update status user
      .addCase(updateStatusUserAction.pending, state => {
        state.updateStatus.loading = true;
      })
      .addCase(updateStatusUserAction.fulfilled, state => {
        state.updateStatus.loading = false;
      })
      .addCase(updateStatusUserAction.rejected, (state, action) => {
        state.updateStatus.loading = false;
        state.error = action.error;
      })

      // delete  user
      .addCase(deleteUserAction.pending, state => {
        state.deleteUser.loading = true;
      })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        state.deleteUser.loading = false;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.deleteUser.loading = false;
        state.error = action.error;
      })

      // detail  user
      .addCase(getDetailUserAction.pending, state => {
        state.detailUser.loading = true;
        state.detailUser.data = undefined;
      })
      .addCase(getDetailUserAction.fulfilled, (state, action) => {
        state.detailUser.loading = false;
        state.detailUser.data = action.payload;
      })
      .addCase(getDetailUserAction.rejected, (state, action) => {
        state.detailUser.loading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
