import moment from 'moment-timezone';

export const LIST_META_TITLES = [
  'title',
  'og:title',
  'meta[name="og:title"]',
  'meta[property="og:title"]',
  'meta[name="twitter:title"]',
  'meta[property="twitter:title"]',
  'og:site_name',
  'meta[property="og:site_name"]',
  'meta[name="og:site_name"]',
];
export const LIST_META_DESCRIPTIONS = [
  'meta[name="description"]',
  'meta[name="og:description"]',
  'meta[name="twitter:description"]',
  'meta[property="description"]',
  'meta[property="og:description"]',
  'meta[property="twitter:description"]',
  'twitter:description',
];
export const LIST_META_IMAGES = [
  'meta[property="og:image"]',
  'meta[property="twitter:image"]',
  'meta[name="og:image"]',
  'meta[name="twitter:image"]',
];

export const LIST_TAB_ACTIVE = [
  { name: 'Active', value: 1, members: 0 },
  { name: 'Deactive', value: 2, members: 0 },
  { name: 'Blocked', value: 3, members: 0 },
];

export const DATA_SORT_BY_1 = [
  { label: 'All', value: '' },
  {
    label: 'Name A->Z',
    value: 'name|asc',
  },
  {
    label: 'Name Z->A',
    value: 'name|desc',
  },
  {
    label: 'Recent sign in',
    value: 'last_sign_in|desc',
  },
  {
    label: 'Oldest sign in',
    value: 'last_sign_in|asc',
  },
  {
    label: 'Last created',
    value: 'created_at|desc',
  },
  {
    label: 'Oldest created',
    value: 'created_at|asc',
  },
  {
    label: 'Updated date',
    value: 'updated_date|desc',
  },
  {
    label: 'Oldest updated',
    value: 'oldest_updated|asc',
  },
  {
    label: 'Recent last activity',
    value: 'last_activity|desc',
  },
  {
    label: 'Oldest last activity',
    value: 'last_activity|asc',
  },
];

export const DATA_SORT_BY_2 = [
  { label: 'All', value: '' },
  { label: 'Last created', value: 'desc' },
  { label: 'Oldest created', value: 'asc' },
];

export function secondsToHoursMinutes(seconds) {
  // Calculate hours and minutes
  const number = Number(seconds);
  const hours = Math.floor(number / 3600);
  const remainingSeconds = number % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  // Format the result
  const formattedResult = `${hours}h ${minutes}m`;

  return formattedResult;
}

export function getTimeStatus(time) {
  const currentTime = moment();
  const targetTime = moment(time).tz('UTC'); // Assuming your time is in UTC

  const minutesDifference = currentTime.diff(targetTime, 'minutes');

  if (minutesDifference >= 0 && minutesDifference <= 3) {
    return 'Active';
  } else {
    const prefixes = ['year', 'month', 'week', 'day', 'hour', 'minute'];

    for (const prefix of prefixes) {
      const unit = getUnit(prefix, minutesDifference);
      if (unit) {
        return `${unit} ${prefix}${unit > 1 ? 's' : ''} ago`;
      }
    }
  }
}

function getUnit(prefix, minutes) {
  switch (prefix) {
    case 'year':
      return Math.floor(minutes / (365 * 24 * 60));
    case 'month':
      return Math.floor(minutes / (30 * 24 * 60));
    case 'week':
      return Math.floor(minutes / (7 * 24 * 60));
    case 'day':
      return Math.floor(minutes / (24 * 60));
    case 'hour':
      return Math.floor(minutes / 60);
    case 'minute':
      return minutes;
    default:
      return null;
  }
}

export const KEY_PERMISSION = {
  DASHBOARD_USER_ACTIVITY: 'dashboard_user_activity',
  DASHBOARD_MATCHING_CONNECTION: 'dashboard_matching_connection',
  DASHBOARD_USER_ENGAGEMENT: 'dashboard_user_engagement',
  DASHBOARD_KEYWORD_TRENDING: 'dashboard_keyword_trending',
  DASHBOARD_HISTORY_ACTIVITY: 'dashboard_history_activity',
  DASHBOARD_SHARE_PROFILE_METHOD: 'dashboard_share_profile_method',
  DASHBOARD_HIDE_PROFILE: 'dashboard_hide_profile',
  SETTINGS_LANGUAGES_CONFIG: 'settings_languages_config',
  USERS_DELETE: 'users_delete',
  USERS_BLOCK_DEACTIVE: 'users_block_deactive',
  USERS_MAXIMUM_MATCHING_CONNECTION: 'users_maximum_matching_connection',
  SETTINGS_ADD_CATEGORY: 'settings_add_category',
  SETTINGS_DELETE_CATEGORY: 'settings_delete_category',
  SETTINGS_MODIFY_CATEGORY: 'settings_modify_category',
  USERS_ADMIN_BLOCK_DEACTIVE: 'users_admin_block_deactive',
  USERS_ADD_DELETE_ADMIN_ACCOUNT: 'users_add_delete_admin_account',
  USERS_SET_ROLE_ADMIN_ACCOUNT: 'users_set_role_admin_account',
  THEME_CONFIG: 'theme_config',
};

export const STATUS_RENDER = { 1: 'Draft', 2: 'Pending review', 3: 'Published' };

export const TYPE_DATA = {
  group_header: 'group_header',
  group_feature: 'group_feature',
  group_text: 'group_text',
  group_images: 'group_images',
  group_normal: 'group_normal',
  text_button: 'text_button',
  dropdown: 'dropdown',
  text: 'text',
  text_editor: 'text_editor',
  link: 'link',
  image: 'image',
};

export const ID_SECTION_HOME_PAGE = {
  header: {
    label: 'Header section',
    value: 'landing_page_header',
  },
  main: {
    label: 'Main section',
    value: 'landing_page_main',
  },
  feature: {
    label: 'Features section',
    value: 'landing_page_features',
  },
  enrich: {
    label: 'Enrich section',
    value: 'landing_page_enrich',
  },
  why: {
    label: 'Why choose us section',
    value: 'landing_page_why_choose_us',
  },
  deloy: {
    label: 'Deploy section',
    value: 'landing_page_deploy',
  },
  price: {
    label: 'Price section',
    value: 'landing_page_price',
  },
  contact: {
    label: 'Contact section',
    value: 'landing_page_contact',
  },
  download: {
    label: 'Download section',
    value: 'landing_page_download',
  },
  footer: {
    label: 'Footer section',
    value: 'landing_page_footer',
  },
};

export const demo_data = {
  id: '0462aff1-ce6b-4b8a-80f8-1022344c7292',
  name: 'Main',
  url: null,
  language: 2,
  status: null,
  published_at: null,
  content: '1',
  flag: 1,
  part: [
    {
      id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
      name: 'Header section',
      slug: 'landing_page_header',
      order: 0,
      part_field: [
        {
          id: 'aefde56c-ad86-4efe-a38e-86e9f1f07120',
          name: 'Header 1',
          content: '',
          part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
          field_parent_id: '',
          type: 'group_header',
          order: 0,
          slug: 'group_header',
          children: [
            {
              id: '9481c213-22a5-4492-83a6-40260047c739',
              name: 'Title',
              content: 'Features',
              part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
              field_parent_id: 'aefde56c-ad86-4efe-a38e-86e9f1f07120',
              type: 'text_button',
              order: 0,
              slug: 'title',
            },
            {
              id: '76c4a8bc-ee8f-4e6f-a436-a491590b39d1',
              name: 'Link to',
              content: null,
              part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
              field_parent_id: 'aefde56c-ad86-4efe-a38e-86e9f1f07120',
              type: 'dropdown',
              order: 0,
              slug: 'linkTo',
            },
          ],
        },
        {
          id: '1dea64a2-b748-45c5-b48f-f71fc08411f6',
          name: 'Header 2',
          content: '',
          part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
          field_parent_id: '',
          type: 'group_header',
          order: 0,
          slug: 'group_header',
          children: [
            {
              id: '8b9a38d5-7653-4747-bae6-4b81bdaf32a8',
              name: 'Title',
              content: 'Book a demo',
              part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
              field_parent_id: '1dea64a2-b748-45c5-b48f-f71fc08411f6',
              type: 'text_button',
              order: 0,
              slug: 'title',
            },
            {
              id: '9494d74d-baf6-4392-9fd5-d518b57151e0',
              name: 'Link to',
              content: null,
              part_id: 'ad990a8c-a55a-4e73-9f6c-e2758d2e74b7',
              field_parent_id: '1dea64a2-b748-45c5-b48f-f71fc08411f6',
              type: 'dropdown',
              order: 0,
              slug: 'linkTo',
            },
          ],
        },
      ],
    },
    {
      id: 'e5cec928-24ee-4871-b301-aea4139bc330',
      name: 'Main section',
      slug: 'landing_page_main',
      order: 1,
      part_field: [
        {
          id: '3156e1a5-3c1f-4b1a-a7c5-3f121c131389',
          name: 'Header title',
          content: 'Multi - Matching platform',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: '666b8779-5aa0-4e5b-bfdc-c53ae176f0e8',
          name: 'Title ',
          content: 'Cultivate a wide-ranging network for your own business',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'text_editor',
          order: 0,
          slug: 'content',
        },
        {
          id: 'e16dcacb-cdc2-4e2b-baf2-68e060d67990',
          name: 'Description',
          content:
            'Easily create a platform with matching algorithm, smart suggestion. All fields are accessible with variety modules.',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subContent',
        },
        {
          id: 'e80c2cba-f2d9-4f33-906d-c7625ec7b147',
          name: 'Title for CTA button',
          content: 'Try beta test',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'titleBtn',
        },
        {
          id: '5869731a-df0c-4634-8a6d-2e747d18ac39',
          name: 'Link for Android',
          content: '',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'linkAndroid',
        },
        {
          id: '576dfda4-faab-48ed-955f-e90980c56ec8',
          name: 'Link for iOS',
          content: '',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'linkIOS',
        },
        {
          id: '971b6f04-88b0-4565-bcff-1fa8e0248b08',
          name: 'Link images',
          content: '',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'image',
          order: 0,
          slug: 'imageUrl',
        },
        {
          id: 'ac6e5043-25aa-45b5-9044-7314b1b28c07',
          name: 'Link video',
          content: '',
          part_id: 'e5cec928-24ee-4871-b301-aea4139bc330',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'videoUrl',
        },
      ],
    },
    {
      id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
      name: 'Features section',
      slug: 'landing_page_features',
      order: 2,
      part_field: [
        {
          id: '842cd52e-c8bb-4819-b7ed-37a539fac725',
          name: 'Title',
          content: 'WHAT WE OFFER',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: 'ce4dcca2-3a52-4839-9ded-504fa20c1fe4',
          name: 'Subtitle',
          content: 'Matching solution in a variety of fields',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: 'a03c438c-aa36-4aa8-b8ca-30c879a4baa0',
          name: 'Subtitle 2',
          content: 'Cross-border connections and find the right partner, great business opportunities',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle2',
        },
        {
          id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
          name: 'Feature 1',
          content: '',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'group_feature',
          order: 0,
          slug: 'feature_1',
          children: [
            {
              id: '206e8b99-53b7-4919-aa4f-02ead51329c1',
              name: 'Name',
              content: 'Professional',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'text',
              order: 0,
              slug: 'name',
            },
            {
              id: '86b84a0c-161f-4e58-9346-835a482ec388',
              name: 'Description',
              content: 'Fast-track your professional network building and ownership',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '57a09df3-7beb-4dfe-82d4-027fbad9cd42',
              name: 'Sub-description',
              content:
                'Cluster your expert network in any specific field and quickly expand it by intelligent matching algorithm',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '6b9080ba-010f-4ccf-af76-0b7e8094cf03',
              name: 'Image 2',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'img2',
            },
            {
              id: 'c939c5a4-f827-4d12-a964-e30d30f5f1ad',
              name: 'Image 3',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'img3',
            },
            {
              id: '57b52d4d-bc0b-47e7-9752-1529debe58f9',
              name: 'Image 4',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'img4',
            },
            {
              id: '52a4f5cf-59cd-44c3-838c-32626f5f7155',
              name: 'Image 5',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'img5',
            },
            {
              id: '750ed160-3835-46e3-bdfe-10af7d32fe2e',
              name: 'Main image',
              content:
                'https://matching-storage.s3.ap-southeast-1.amazonaws.com/matching-storage/storage_manager/drive/da10c40c-0792-436d-8148-7dc0130c49ea/global/ad0bcb54-eba1-43c6-a831-e93e1dae8412.png',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: '3fd68026-355b-44ed-8a77-e8488c2b09f3',
              name: 'Image 1',
              content:
                'https://matching-storage.s3.ap-southeast-1.amazonaws.com/matching-storage/storage_manager/drive/ec23650d-0324-48f7-a62d-c6824c87f74c/global/23a57ab7-43c7-48bf-9ad3-732450d5e8c6.png',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '62c1dda8-420e-44ef-8418-5d1fa8fbba5d',
              type: 'image',
              order: 0,
              slug: 'img1',
            },
          ],
        },
        {
          id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
          name: 'Feature 2',
          content: '',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'group_feature',
          order: 0,
          slug: 'feature_2',
          children: [
            {
              id: '64a901a1-129a-4b58-9b28-b0b6eaeb988a',
              name: 'Name',
              content: 'Recruitment',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'text',
              order: 0,
              slug: 'name',
            },
            {
              id: 'a30811fb-d69a-4bc7-9517-d571040a2fee',
              name: 'Description',
              content: 'Connect job seekers and employers for streamlined hiring',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '8dfd09a8-52a6-4f84-9ab1-6cadc8f14385',
              name: 'Sub-description',
              content:
                'Identify the appropriate candidate and job with a high level of compatibility between their personality and skills.',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: 'a9206a45-99ba-4d8e-9e10-76bef7766d01',
              name: 'Main image',
              content: 'Hình cái điện thoại',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: '9aa11159-b3a2-4b0f-85dd-20e6a47f703b',
              name: 'Image 1',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'img1',
            },
            {
              id: 'd2566876-27b7-422d-a684-9eb79cc6ce27',
              name: 'Image 2',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'img2',
            },
            {
              id: '06e45ba6-1261-42aa-a0a5-d14a594110fa',
              name: 'Image 3',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'img3',
            },
            {
              id: '27d6e42d-a017-4e68-b8ea-54b861f3c755',
              name: 'Image 4',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'img4',
            },
            {
              id: '646f376d-6cd2-4653-9aad-58738e101456',
              name: 'Image 5',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a6787d0a-2c7e-4c60-90d2-b0695751a272',
              type: 'image',
              order: 0,
              slug: 'img5',
            },
          ],
        },
        {
          id: '20d55436-5664-45af-9e54-97afccc667c3',
          name: 'Feature 3',
          content: '',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'group_feature',
          order: 0,
          slug: 'feature_3',
          children: [
            {
              id: 'c40376cf-a0f1-477c-8a97-236f73def217',
              name: 'Name',
              content: 'Investment',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'text',
              order: 0,
              slug: 'name',
            },
            {
              id: '528fa0ee-5477-44c5-a6f3-9559b014b62a',
              name: 'Description',
              content: 'Linking investors and startups for visionary projects',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: 'bab76321-2bab-4dd2-9620-6eef4292190b',
              name: 'Sub-description',
              content:
                'Bringing investors and startups together through a wide range of projects that each can be interested in.',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: 'c8f1235f-404f-425e-8c77-a40aec4e0498',
              name: 'Main image',
              content: 'Hình cái điện thoại',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: '7fb083ad-c698-4c79-8eca-caa751297257',
              name: 'Image 1',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'img1',
            },
            {
              id: '4a1d322b-70fd-471c-a223-891803dea8fd',
              name: 'Image 2',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'img2',
            },
            {
              id: '40596a71-682d-4287-8872-d21bbace0919',
              name: 'Image 3',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'img3',
            },
            {
              id: 'd9e2c898-3f07-4df4-b821-4efa146738a5',
              name: 'Image 4',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'img4',
            },
            {
              id: '81fd4885-7c31-43fd-ba3a-3fda42f73df1',
              name: 'Image 5',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: '20d55436-5664-45af-9e54-97afccc667c3',
              type: 'image',
              order: 0,
              slug: 'img5',
            },
          ],
        },
        {
          id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
          name: 'Feature 4',
          content: '',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'group_feature',
          order: 0,
          slug: 'feature_4',
          children: [
            {
              id: 'cf059155-2596-409f-809e-2717d266868c',
              name: 'Name',
              content: 'Renting',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'text',
              order: 0,
              slug: 'name',
            },
            {
              id: '7cfd4254-acf1-44ee-957e-bb2c6c76dea0',
              name: 'Description',
              content: 'Simplify landlord-tenant connections with ideal rentals',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '352ea8c2-467c-4163-a845-48b71cbbe1f7',
              name: 'Sub-description',
              content:
                'Make it easy for landlords to connect with tenants by providing a platform they can showcase and find their perfect rental.',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '285ef8d1-bfc8-4261-b0b4-4ec02da7b075',
              name: 'Main image',
              content: 'Hình cái điện thoại',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: 'b72c8179-441c-4f3f-896b-a4506a7b75fe',
              name: 'Image 1',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'img1',
            },
            {
              id: '4429621c-e1e4-4d96-ac25-22df025ba0b9',
              name: 'Image 2',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'img2',
            },
            {
              id: 'a74b5e7b-2c92-41a8-9979-bb7910084f98',
              name: 'Image 3',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'img3',
            },
            {
              id: '8ff2234f-f597-4fd4-9f6b-bf0faef436b5',
              name: 'Image 4',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'img4',
            },
            {
              id: 'af67e227-6514-4264-9d7e-08e8bbad580e',
              name: 'Image 5',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'a8ce33f9-67b5-41c4-a0c2-afb4d5aad4c0',
              type: 'image',
              order: 0,
              slug: 'img5',
            },
          ],
        },
        {
          id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
          name: 'Feature 5',
          content: '',
          part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
          field_parent_id: '',
          type: 'group_feature',
          order: 0,
          slug: 'feature_5',
          children: [
            {
              id: '9dcfd0b1-fe8e-4881-9dcb-c9946cccfa7e',
              name: 'Name',
              content: 'Dating',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'text',
              order: 0,
              slug: 'name',
            },
            {
              id: '50b921fa-434a-46fd-831e-0940b9f1923c',
              name: 'Description',
              content: 'Discover your soulmate in our connected world',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '5bb3ef6e-52fa-4f9c-a3e5-b7c08702027f',
              name: 'Sub-description',
              content: 'Find the right soulmate and transcend boundaries and open door to lasting relationship.',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '2616dd11-c5e3-4008-8ff0-956a50946276',
              name: 'Main image',
              content: 'Hình cái điện thoại',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: 'dcf09dae-7c66-4798-9716-3bb39f131f87',
              name: 'Image 1',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'img1',
            },
            {
              id: '2ec733f0-cccd-45de-a50b-0fe549b5eb1f',
              name: 'Image 2',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'img2',
            },
            {
              id: '7383bb83-804e-4eba-ba9f-090a962b8645',
              name: 'Image 3',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'img3',
            },
            {
              id: 'a982df62-e567-43b1-9dbf-7942e98baac9',
              name: 'Image 4',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'img4',
            },
            {
              id: 'a41e34eb-4280-4dba-98e8-8ffe538a5a34',
              name: 'Image 5',
              content: '',
              part_id: '4eee80e3-36d6-481e-93fa-b4358f0b8c38',
              field_parent_id: 'ca10cfa6-04a7-48c1-9428-0a1e03b03c40',
              type: 'image',
              order: 0,
              slug: 'img5',
            },
          ],
        },
      ],
    },
    {
      id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
      name: 'Enrich section',
      slug: 'landing_page_enrich',
      order: 3,
      part_field: [
        {
          id: '7dc71696-8f01-47c9-9644-e8202ed3178b',
          name: 'Title',
          content: 'LET`S ENRICH YOUR BUSINESS',
          part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: 'd13f5c9d-2e3d-434c-8ed4-2f6585e1ddda',
          name: 'Description',
          content: 'Amplify your power with us',
          part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
          name: 'Content items',
          content: '',
          part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
          field_parent_id: '',
          type: 'group_text',
          order: 0,
          slug: 'group_text_items',
          children: [
            {
              id: '06dfd159-2057-43d8-8945-d739d509d7cb',
              name: 'Item1',
              content: 'Quickly transform ideas into reality. Eliminate the time spent on figuring out your next steps',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
              type: 'text',
              order: 0,
              slug: 'item1',
            },
            {
              id: '27a88cf3-8637-41ad-a8e4-0d04b914065d',
              name: 'Item2',
              content: 'Conveniently manage the platforms overview with a variety of reports',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
              type: 'text',
              order: 0,
              slug: 'item2',
            },
            {
              id: 'e318fffe-bd7d-4edc-9c19-a545362d2978',
              name: 'Item3',
              content: 'Reach potential customers within a short period of time',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
              type: 'text',
              order: 0,
              slug: 'item3',
            },
            {
              id: '6933d426-212c-41db-9bb4-c061971d551b',
              name: 'Item4',
              content: 'Professional services support the integration',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
              type: 'text',
              order: 0,
              slug: 'item4',
            },
            {
              id: 'a4946a78-c5a4-4a23-8012-d4426f2b8010',
              name: 'Item5',
              content: 'Flexible user interface allows customization of many fields',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: '5b36ee29-ed4b-4ef5-afe1-95b3bebc65a2',
              type: 'text',
              order: 0,
              slug: 'item5',
            },
          ],
        },
        {
          id: 'd611f25f-ce3c-4ac4-a412-60d2fd093a40',
          name: 'Content images',
          content: '',
          part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
          field_parent_id: '',
          type: 'group_images',
          order: 0,
          slug: 'group_images_item',
          children: [
            {
              id: '4e8f1817-e3dc-4607-9995-f732a9df7679',
              name: 'Main image',
              content: '',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: 'd611f25f-ce3c-4ac4-a412-60d2fd093a40',
              type: 'link',
              order: 0,
              slug: 'mainImg',
            },
            {
              id: 'cd1e7ad8-e774-491f-8db5-ed97c7f53321',
              name: 'Left image',
              content: '',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: 'd611f25f-ce3c-4ac4-a412-60d2fd093a40',
              type: 'link',
              order: 0,
              slug: 'leftImg',
            },
            {
              id: '276afbe8-37e3-46d5-a599-8e949ef7768f',
              name: 'Right image',
              content: '',
              part_id: 'cb369cb2-e8b4-4762-8a0e-b7829bead1bf',
              field_parent_id: 'd611f25f-ce3c-4ac4-a412-60d2fd093a40',
              type: 'link',
              order: 0,
              slug: 'rightImg',
            },
          ],
        },
      ],
    },
    {
      id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
      name: 'Why choose us section',
      slug: 'landing_page_why_choose_us',
      order: 4,
      part_field: [
        {
          id: '81cfe103-8a08-4765-836b-3da5997c93b8',
          name: 'Title',
          content: 'WHY CHOOSE US',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: '9e545bfd-bb2d-4d0e-9f4f-a91774734812',
          name: 'Description',
          content: 'Leverage your business development potential',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: '9315ba3d-c8c1-4a1e-a37c-2ab1b96f33d4',
          name: 'Item 1',
          content: '',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '67558411-dc58-4f53-b8ba-02fcb502021a',
              name: 'Title',
              content: 'Quick deployment',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '9315ba3d-c8c1-4a1e-a37c-2ab1b96f33d4',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '19827a41-5138-4a0a-afd8-9f1726528db9',
              name: 'Description',
              content:
                'Finding the right person, getting started took a short time. Launch 20x faster thanks to templates and features.',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '9315ba3d-c8c1-4a1e-a37c-2ab1b96f33d4',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '1f6975b9-5b06-43c0-960f-c1a1bee1ca10',
              name: 'Image',
              content: '',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '9315ba3d-c8c1-4a1e-a37c-2ab1b96f33d4',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '02ff03d7-66bb-47a1-9ea4-7ca8c0e6c637',
          name: 'Item 2',
          content: '',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '2b0f053d-b386-4c0f-ad74-f1a839000b50',
              name: 'Title',
              content: 'Easy management',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '02ff03d7-66bb-47a1-9ea4-7ca8c0e6c637',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '5bfdc272-57c9-41f5-a9a1-d5f1843f1b22',
              name: 'Description',
              content:
                'The advanced enterprise-grade security features of our platform give you confidence in controlling.',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '02ff03d7-66bb-47a1-9ea4-7ca8c0e6c637',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '095b8ade-3f3e-4d64-a3c4-d27cc8c4be23',
              name: 'Image',
              content: '',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '02ff03d7-66bb-47a1-9ea4-7ca8c0e6c637',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: 'e291a20b-12c7-44e9-a3b6-6495173a84f6',
          name: 'Item 3',
          content: '',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: 'a805f02f-3d7f-4d5c-a623-3ff3d5662953',
              name: 'Title',
              content: 'Adaptable to business changes',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: 'e291a20b-12c7-44e9-a3b6-6495173a84f6',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '1cbcad66-a035-4aa4-9417-5c82900cac8f',
              name: 'Description',
              content:
                'Applications can be integrated easier, incorporating evolving business requirements into their functionality.',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: 'e291a20b-12c7-44e9-a3b6-6495173a84f6',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: 'b8a6905a-580c-4868-bec8-e86424e95492',
              name: 'Image',
              content: '',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: 'e291a20b-12c7-44e9-a3b6-6495173a84f6',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '6d147ebc-e4d2-4eb5-a1ab-8547453a664d',
          name: 'Item 4',
          content: '',
          part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: 'da04c072-580b-43e2-afdb-df9f6f98694d',
              name: 'Title',
              content: 'Outstanding features',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '6d147ebc-e4d2-4eb5-a1ab-8547453a664d',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '98cb3bc4-dfc8-4013-bdb6-bfb0266d7327',
              name: 'Description',
              content:
                'Utilize technology and deep understanding of users to make suggestions that are close to what users want.',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '6d147ebc-e4d2-4eb5-a1ab-8547453a664d',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: 'f00ab3ea-cc8b-4a9b-914d-7f8e73dcc6d4',
              name: 'Image',
              content: '',
              part_id: '6588e5a2-096a-41ed-9556-2c58741dd4e0',
              field_parent_id: '6d147ebc-e4d2-4eb5-a1ab-8547453a664d',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
      ],
    },
    {
      id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
      name: 'Deploy section',
      slug: 'landing_page_deploy',
      order: 5,
      part_field: [
        {
          id: 'edb62bd7-c3ef-4ee7-bdd2-faa43adc0915',
          name: 'Title',
          content: 'HOW EASY IT TO DEPLOYS',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: '4cf19176-3660-449b-930e-9331f3fc67e7',
          name: 'Description',
          content: 'The growth of a business will be supported in all aspects',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: '20e3fa82-c643-4749-b0dd-942f425bb2cc',
          name: 'Step 1',
          content: '',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '011af190-d771-47ee-9c5b-4a902a90afb4',
              name: 'Title',
              content: 'Received requests',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '20e3fa82-c643-4749-b0dd-942f425bb2cc',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: 'a65169e6-2593-4ad2-aeaf-0b82041d46b4',
              name: 'Description',
              content: 'We received your requests',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '20e3fa82-c643-4749-b0dd-942f425bb2cc',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '0248c375-929f-490c-bf0b-6769d8f0cd63',
              name: 'Image',
              content: '',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '20e3fa82-c643-4749-b0dd-942f425bb2cc',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '9a923149-7cb0-41c2-a510-0226e65e8702',
          name: 'Step 2',
          content: '',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '1f9f45d1-7723-4645-8033-db15b0d0823d',
              name: 'Title',
              content: 'Customize and detail',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '9a923149-7cb0-41c2-a510-0226e65e8702',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '9465b00b-9666-46c3-8542-8907172e39ed',
              name: 'Description',
              content: 'Provide a customized solution based on your needs, details the modules',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '9a923149-7cb0-41c2-a510-0226e65e8702',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '100e1c4e-3d80-4271-be15-c0f752432393',
              name: 'Image',
              content: '',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '9a923149-7cb0-41c2-a510-0226e65e8702',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '3412d69f-3edf-4826-93f8-3e46906cf39c',
          name: 'Step 3',
          content: '',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '6916cd96-6b42-4446-b3cb-ad71e4f426f6',
              name: 'Title',
              content: 'Sign and pay',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '3412d69f-3edf-4826-93f8-3e46906cf39c',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '0fa6b3ae-d2f9-4b44-9314-63fb2f670d17',
              name: 'Description',
              content: 'Sign and pay the contract after accepting the terms',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '3412d69f-3edf-4826-93f8-3e46906cf39c',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: 'fc14cbe7-34a7-469a-9a7c-2f9085feb902',
              name: 'Image',
              content: '',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '3412d69f-3edf-4826-93f8-3e46906cf39c',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '6d269e1f-4eed-4252-9810-12eb51954bae',
          name: 'Step 4',
          content: '',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '0712dc58-6a25-4b1e-b0dd-8afe2a589989',
              name: 'Title',
              content: 'Deploy and set up',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '6d269e1f-4eed-4252-9810-12eb51954bae',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: 'e21e58cc-c3c7-45ee-a171-9e3051db6462',
              name: 'Description',
              content: 'Implement and set up the system for business according to the request',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '6d269e1f-4eed-4252-9810-12eb51954bae',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '25fd90b3-02f6-4166-8702-bc7a5d88f1c0',
              name: 'Image',
              content: '',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '6d269e1f-4eed-4252-9810-12eb51954bae',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
        {
          id: '38eadb5d-d964-4456-932f-56bc886501ce',
          name: 'Step 5',
          content: '',
          part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
          field_parent_id: '',
          type: 'group_normal',
          order: 0,
          slug: '',
          children: [
            {
              id: '83eb0375-995e-45a0-9cd2-47360970b209',
              name: 'Title',
              content: 'Support, scale up',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '38eadb5d-d964-4456-932f-56bc886501ce',
              type: 'text',
              order: 0,
              slug: 'title',
            },
            {
              id: '96fc3e1d-8b58-4bb3-88c4-f96d879dce7e',
              name: 'Description',
              content: 'We support and expand according to the enterprise`s scale-up',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '38eadb5d-d964-4456-932f-56bc886501ce',
              type: 'text',
              order: 0,
              slug: 'content',
            },
            {
              id: '810eede6-1118-46e8-a738-d924c8aa2503',
              name: 'Image',
              content: '',
              part_id: 'e6516e32-797e-40f9-ad30-c5c8be0247a2',
              field_parent_id: '38eadb5d-d964-4456-932f-56bc886501ce',
              type: 'image',
              order: 0,
              slug: 'image',
            },
          ],
        },
      ],
    },
    {
      id: '339f65dd-ac57-420f-9790-b78bdb261510',
      name: 'Price section',
      slug: 'landing_page_price',
      order: 6,
      part_field: [
        {
          id: '451d21d4-4c7d-43b3-88bd-272de8c85b20',
          name: 'Title',
          content: 'Get ready to transfer your business now',
          part_id: '339f65dd-ac57-420f-9790-b78bdb261510',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: '9d33f83e-be94-4b8a-8ebb-1c3edd640f8a',
          name: 'Description',
          content: 'Get ready to transfer your business now',
          part_id: '339f65dd-ac57-420f-9790-b78bdb261510',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: '6ce4945d-1d5c-4d5e-8067-6c8eca0411f7',
          name: 'Image left',
          content:
            'https://matching-storage.s3.ap-southeast-1.amazonaws.com/matching-storage/storage_manager/drive/da10c40c-0792-436d-8148-7dc0130c49ea/global/5dccb3a9-3d26-43f8-b68b-e922e605b141.png',
          part_id: '339f65dd-ac57-420f-9790-b78bdb261510',
          field_parent_id: '',
          type: 'image',
          order: 0,
          slug: 'imageLeft',
        },
        {
          id: '26174dfd-f920-4757-9cb8-d9f3f4c47d8a',
          name: 'Image right',
          content:
            'https://matching-storage.s3.ap-southeast-1.amazonaws.com/matching-storage/storage_manager/drive/da10c40c-0792-436d-8148-7dc0130c49ea/global/69b5a3e1-81e3-4d58-8f1d-fc5681025b5a.png',
          part_id: '339f65dd-ac57-420f-9790-b78bdb261510',
          field_parent_id: '',
          type: 'image',
          order: 0,
          slug: 'imageRight',
        },
      ],
    },
    {
      id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
      name: 'Contact section',
      slug: 'landing_page_contact',
      order: 7,
      part_field: [
        {
          id: '5fef9a8c-9638-4bfe-bf36-7e4eb0e62dfa',
          name: 'Tilte',
          content: 'CONTACT',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: '0386bba7-5340-4fed-8a45-79acb1d0de86',
          name: 'Description',
          content: 'Get consultation',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'subTitle',
        },
        {
          id: 'c913911b-0e14-4b59-9d2d-079c3353a39a',
          name: 'Sub-description',
          content: 'Are you ready to transform your business from 12000$?',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'description',
        },
        {
          id: 'ba35584c-0992-46f3-a249-fa6387ed5ab3',
          name: 'Sub-description 2',
          content: 'Fill this form more information about building a platform that resolves the above needs.',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'formTitle',
        },
        {
          id: 'd0db6a5b-b033-40ef-9526-1b361ba07733',
          name: 'Field 1',
          content: 'Your name',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'hintName',
        },
        {
          id: '445ee492-9e19-467e-b5f3-49ef9b1d23d8',
          name: 'Field 2',
          content: 'Your email',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'hintEmail',
        },
        {
          id: 'ea345304-1b94-4b0a-bbe7-12dd56320cde',
          name: 'Field 3',
          content: 'Write a message',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'hintform',
        },
        {
          id: '33243341-d532-481c-93a9-72f6d42bac6f',
          name: 'Button 2',
          content: 'Submit',
          part_id: '38d1711a-eb43-472f-a801-5ea1d87eb6ed',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'titleBtn',
        },
      ],
    },
    {
      id: '099da318-d468-4683-b8a9-eab1162e92b2',
      name: 'Download section',
      slug: 'landing_page_download',
      order: 8,
      part_field: [
        {
          id: '195b4eea-6cab-468a-bbf2-8cf24ba72981',
          name: 'Tilte',
          content: 'Download the matching platform now!',
          part_id: '099da318-d468-4683-b8a9-eab1162e92b2',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'title',
        },
        {
          id: 'e13d05d9-18f9-4791-b7dd-753bce24c418',
          name: 'Description',
          content: 'Unlock the value of connection - All in one platform',
          part_id: '099da318-d468-4683-b8a9-eab1162e92b2',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'description',
        },
        {
          id: '5e57d357-4c56-4eaf-b825-d3b01e4b8792',
          name: 'Title for CTA button',
          content: 'Try beta test',
          part_id: '099da318-d468-4683-b8a9-eab1162e92b2',
          field_parent_id: '',
          type: 'text',
          order: 0,
          slug: 'titleBtn',
        },
        {
          id: 'aee1bf7d-57f9-4483-91cd-cd8cb027a533',
          name: 'Link for Android',
          content: '',
          part_id: '099da318-d468-4683-b8a9-eab1162e92b2',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'linkAndroid',
        },
        {
          id: 'da8e9947-df28-4769-a7ed-7ab9a37e77c2',
          name: 'Link for iOS',
          content: '',
          part_id: '099da318-d468-4683-b8a9-eab1162e92b2',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'linkIOS',
        },
      ],
    },
    {
      id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
      name: 'Footer section',
      slug: 'landing_page_footer',
      order: 9,
      part_field: [
        {
          id: '92a24413-5ec2-452e-a366-018cff1132e2',
          name: 'Phone',
          content: '(+84) (286) 2701 557',
          part_id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
          field_parent_id: '',
          type: 'phone',
          order: 0,
          slug: 'phoneNumber',
        },
        {
          id: 'cf10a58f-24a5-492a-8427-965da167d751',
          name: 'Email',
          content: 'sales@bap.jp',
          part_id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
          field_parent_id: '',
          type: 'email',
          order: 0,
          slug: 'contactEmail',
        },
        {
          id: '79ca11af-6bf9-41f4-8bfd-0b4479d6eb81',
          name: 'Facebook link',
          content: 'https://www.facebook.com/bap32',
          part_id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'facebookLink',
        },
        {
          id: '7f55ccc1-4a04-44d8-8c7a-b0958f466184',
          name: 'Linkedin link',
          content: 'https://www.linkedin.com/company/bap-it-jsc/',
          part_id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'linkedinLink',
        },
        {
          id: '995b1206-2c50-407c-b5dc-ec8d77e6b760',
          name: 'Messenger link',
          content: 'https://www.facebook.com/messages/t/248286005503837',
          part_id: '9ad48fad-86e1-4a21-a9dd-4329d0ec7bab',
          field_parent_id: '',
          type: 'link',
          order: 0,
          slug: 'messengerLink',
        },
      ],
    },
  ],
};
