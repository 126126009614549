export const LANGUAGE = {
  EN: 'en',
  VI: 'vi',
  KR: 'kr',
  JA: 'ja',
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: 'language',
  TOKEN: `${process.env.REACT_APP_TOKEN_KEY_NAME ?? 'token'}`,
  APP_ID: `${process.env.REACT_APP_APPLICATION_ID ?? 'app_id'}`,
  REFRESH_TOKEN: 'refresh_token',
  THEME: 'theme',
  APP_NAME: 'name',
  LIST_PLATFORM: 'list_platform',
  USER_ID: 'user_id',
  ROLE_ADMIN: 'role_admin',
};

export const TYPE_FILE_ACCEPT = ['image/png', 'image/jpeg', 'image/jpg'];

export const SYSTEM_ERROR = {
  NETWORK_ERROR: {
    STATUS: 'Network Error',
    MESSAGE: 'Request has been cancelled',
  },

  TIMEOUT_ERROR: {
    STATUS: 'Request Timeout',
    MESSAGE: 'The Request Has Timed Out',
  },
};

export const HTTP_STATUS = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  REQUEST_TIMEOUT: 408,
  PAYLOAD_TOO_LARGE: 413,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
};

export const THEME_TYPE = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const FORMAT_DATE = {
  FULL: 'YYYY/MM/DD hh:mm:ss',
  YYYYMMDD: 'YYYY-MM-DD',
};

export const ROLE = {
  1: 'Admin',
  3: 'User',
};

export const ROLE_LEVEL = {
  ADMINISTRATOR: 1,
  MODERATOR: 2,
  TRANSLATOR: 3,
  CONTENT_ADMIN: 4,
  SUPER_ADMIN: 5,
};

export const LANGUAGE_RENDER = {
  1: 'VN',
  2: 'EN',
  3: 'JA',
  4: 'KO',
};
