import { createAsyncThunk } from '@reduxjs/toolkit';

import CATEGORY_API from './category.api';

export const getListCategoryAction = createAsyncThunk<any, any>('dj', async (params, { rejectWithValue }) => {
  try {
    const res = await CATEGORY_API.getListCategory(params);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getDetailCategoryAction = createAsyncThunk<any, any>(
  'category_detail',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.detailCategory(id);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getCategoryFieldAction = createAsyncThunk<any, any>(
  'get_category_field',
  async (params, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.getCategoryField(params);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getSubCategoryAction = createAsyncThunk<any, any>(
  'get_sub_category',
  async (params, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.getSubCategory(params);
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getDataSelectCategoryAction = createAsyncThunk<any>(
  'data_screen_category',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.getDataSelectCategory();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getDetailDJAction = createAsyncThunk<any, any>('dj_detail', async (id, { rejectWithValue }) => {
  try {
    const res = await CATEGORY_API.detailDJ(id);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const createCategoryAction = createAsyncThunk<any, any>('create_category', async (data, { rejectWithValue }) => {
  try {
    const response = await CATEGORY_API.createCategory(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const updateCategoryAction = createAsyncThunk<any, any>('update_category', async (data, { rejectWithValue }) => {
  try {
    const response = await CATEGORY_API.updateCategory(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const disableCategoryAction = createAsyncThunk<any, any>(
  'disable_category',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CATEGORY_API.disableCategory(data?.id, data?.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteCategoryAction = createAsyncThunk<any, any>(
  'delete_category',
  async (id, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.deleteCategory(id);
      window.location.reload();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createCategoryFieldAction = createAsyncThunk<any, any>(
  'create_category_field',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CATEGORY_API.createCategoryField(data?.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const updateCategoryFieldAction = createAsyncThunk<any, any>(
  'update_category_field',
  async (data, { rejectWithValue }) => {
    try {
      const response = await CATEGORY_API.updateCategoryField(data?.id, data?.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteCategoryFieldAction = createAsyncThunk<any, any>(
  'delete_category_field',
  async (data, { rejectWithValue }) => {
    try {
      const res = await CATEGORY_API.deleteCategoryField(data?.id);
      data?.callback();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const deleteDJAction = createAsyncThunk<any, any>('delete_dj', async (id, { rejectWithValue }) => {
  try {
    const res = await CATEGORY_API.deleteDJ(id);
    window.location.reload();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateDJAction = createAsyncThunk<any, any>('update_dj', async (data, { rejectWithValue }) => {
  try {
    const response = await CATEGORY_API.updateDJ(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const getGenresAction = createAsyncThunk<any>('genre', async (id, { rejectWithValue }) => {
  try {
    const res = await CATEGORY_API.getGenres();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
