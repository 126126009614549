import React from 'react';
import styled from 'styled-components';
import { IMAGES } from '@/assets';
interface CProps {
  src: any;
  alt?: any;
  className?: any;
  style?: any;
  props?: any;
  [key: string]: any;
}
const ImageStyled = styled.img`
    width: 100%;
    height: 100%;
`;

const Image: React.FC<CProps> = ({ src, alt = '', style, className, ...props }) => {
  return (
    <ImageStyled
      src={src ?? IMAGES.DefaultImage}
      alt={alt}
      style={style}
      className={className}
      onError={(e: any) => {
        e.target.src = IMAGES.ErrorImage;
      }}
      {...props}
    />
  );
};

export default Image;
