import { ROUTES } from '@/constants/routes';
import React from 'react';
import type { RouteObject } from 'react-router-dom';

const LoginScreen = React.lazy(
  async () => await import('@/screens/publicScreens').then(module => ({ default: module.LoginScreen })),
);

const HomePageScreen = React.lazy(
  async () => await import('@/screens/publicScreens').then(module => ({ default: module.HomePageScreen })),
);

const NotFoundScreen = React.lazy(
  async () => await import('@/screens/NotFound').then(module => ({ default: module.NotFound })),
);

const PublicUserScreen = React.lazy(
  async () => await import('@/screens/publicScreens').then(module => ({ default: module.PublicUserScreen })),
);

const _publicRoutes: RouteObject[] = [
  {
    element: <HomePageScreen />,
    path: '/',
  },
  {
    element: <PublicUserScreen />,
    path: '/user/:id',
  },
  {
    element: <LoginScreen />,
    path: ROUTES.ADMIN.LOGIN,
  },
  {
    element: <LoginScreen />,
    path: ROUTES.ADMIN.HOME,
  },
  { element: <NotFoundScreen />, path: '*' },
];

export default _publicRoutes;
