import { createAsyncThunk } from '@reduxjs/toolkit';

import DJ_API from './djs.api';

export const getListDJAction = createAsyncThunk<any, any>('dj', async (params, { rejectWithValue }) => {
  try {
    const res = await DJ_API.getListDJ(params);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getDetailDJAction = createAsyncThunk<any, any>('dj_detail', async (id, { rejectWithValue }) => {
  try {
    const res = await DJ_API.detailDJ(id);
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const createDJAction = createAsyncThunk<any, any>('create_dj', async (data, { rejectWithValue }) => {
  try {
    const response = await DJ_API.createDJ(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const deleteDJAction = createAsyncThunk<any, any>('delete_dj', async (id, { rejectWithValue }) => {
  try {
    const res = await DJ_API.deleteDJ(id);
    window.location.reload();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateDJAction = createAsyncThunk<any, any>('update_dj', async (data, { rejectWithValue }) => {
  try {
    const response = await DJ_API.updateDJ(data?.id, data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const getGenresAction = createAsyncThunk<any>('genre', async (id, { rejectWithValue }) => {
  try {
    const res = await DJ_API.getGenres();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
