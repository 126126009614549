/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import styled from 'styled-components';

import { Spin } from 'antd';

import { LOCAL_STORAGE_KEY } from '@/constants';
import { ROUTES } from '@/constants/routes';
import { useAppSelector } from '@/stores';
import _privateRoutes from './_private';
import _publicRoutes from './_public';

const RootRouter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoading, error } = useAppSelector(state => state.auth);
  const {
    language: { isLoading: isLoading2 },
  } = useAppSelector(state => state.home);
  const listLoading = [isLoading, isLoading2];
  const isLoadingCommon = useMemo(() => listLoading.some(item => item), [listLoading]);
  const [routes, setRoutes] = useState<RouteObject[]>([..._publicRoutes]);

  const appId = localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID);
  const token = localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) as string;

  useEffect(() => {
    if (location.pathname === '/') return;
    if (token) {
      setRoutes([..._publicRoutes, ..._privateRoutes]);
    } else {
      if (location.pathname.includes('/user')) {
        navigate(location.pathname);
        return;
      } else {
        navigate(ROUTES.ADMIN.LOGIN);
      }
      setRoutes([..._publicRoutes]);
    }
  }, [token]);

  useEffect(() => {
    if (location.pathname === '/') return;

    if (error && token) {
      localStorage.removeItem(LOCAL_STORAGE_KEY.TOKEN);
      navigate(ROUTES.ADMIN.LOGIN);
      setRoutes([..._publicRoutes]);
    }
  }, [error]);

  useEffect(() => {
    if (location.pathname === '/') return;

    if (!appId && token) {
      navigate(ROUTES.SELECT_APP);
    }
  }, [appId, token]);

  if (isLoadingCommon) {
    return (
      <SpinStyle spinning={isLoadingCommon} wrapperClassName="root-spin" className="root-spin-component">
        {useRoutes(routes)}
      </SpinStyle>
    );
  } else return <>{useRoutes(routes)}</>;
};

export default RootRouter;

const SpinStyle = styled(Spin)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
