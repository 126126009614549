import { createAsyncThunk } from '@reduxjs/toolkit';
import ROLE_PERMISSION_API from './api';

interface FromPermissionData {
  name: string;
  description: string;
  permission_group_id: string | null;
  role_app: any[];
  key: string;
}

interface CreatePermissionActionData {
  dataSubmit: FromPermissionData;
  callback: () => void;
}

interface EditPermissionActionData {
  id: string;
  dataSubmit: FromPermissionData;
  callback: () => void;
}

interface DeletePermissionActionData {
  id: string;
  callback: () => void;
}

// == used
export const getListAction = createAsyncThunk<any>('list', async (data, { rejectWithValue }) => {
  try {
    const res = await ROLE_PERMISSION_API.getListUser();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const getListRolesAction = createAsyncThunk<any>('listRoles', async (data, { rejectWithValue }) => {
  try {
    const res = await ROLE_PERMISSION_API.getRole();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const createPermissionAction = createAsyncThunk<any, CreatePermissionActionData>(
  'createPermission',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ROLE_PERMISSION_API.createPermisstion(data.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const editPermissionAction = createAsyncThunk<any, EditPermissionActionData>(
  'editPermission',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ROLE_PERMISSION_API.editPermisstion(data.id, data.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deletePermissionAction = createAsyncThunk<any, DeletePermissionActionData>(
  'deletePermission',
  async (data, { rejectWithValue }) => {
    try {
      const res = await ROLE_PERMISSION_API.deletePermission(data.id);
      data.callback();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

// == unused

export const createRoleAction = createAsyncThunk<any, any>('createRoleAction', async (data, { rejectWithValue }) => {
  try {
    const response = await ROLE_PERMISSION_API.createPermisstion(data?.dataSubmit);
    data.callback();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});

export const changeRoleUserAction = createAsyncThunk<any, any>(
  'updatePermission',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ROLE_PERMISSION_API.changeRole(data?.id, data?.dataSubmit);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
