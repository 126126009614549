import { createAsyncThunk } from '@reduxjs/toolkit';
import USER_API from './api';
import { LOCAL_STORAGE_KEY } from '@/constants';

interface FromUserData {
  name: string;
  password: string;
  avatar: string;
  email: string;
  role_app: any[];
}

interface CreateUserActionData {
  dataSubmit: FromUserData;
  callback: () => void;
}

interface ChangeRoleActionData {
  id: string;
  dataSubmit: { role_id: string };
}

interface UpdateStatusUserActionData {
  id: string;
  dataSubmit: { action: string };
  callback: () => void;
}

interface DeleteUserActionData {
  id: string;
  callback: () => void;
}

interface UserDetailActionData {
  id: string;
  tab?: string;
  callbackFailed: () => void;
}

export const getListUserAction = createAsyncThunk<any, any>('listUsers', async (data, { rejectWithValue }) => {
  try {
    const res = await USER_API.getListUser({ ...data, application: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) });
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const createUserAction = createAsyncThunk<any, CreateUserActionData>(
  'createUser',
  async (data, { rejectWithValue }) => {
    try {
      const response = await USER_API.createUser(data.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const changeRoleUserAction = createAsyncThunk<any, ChangeRoleActionData>(
  'updatePermission',
  async (data, { rejectWithValue }) => {
    try {
      const response = await USER_API.changeRole(data.id, data.dataSubmit);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const updateStatusUserAction = createAsyncThunk<any, UpdateStatusUserActionData>(
  'update_status_user',
  async (data, { rejectWithValue }) => {
    try {
      const response = await USER_API.changeStatus(data.id, data.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);

export const deleteUserAction = createAsyncThunk<any, DeleteUserActionData>(
  'delete_user',
  async (data, { rejectWithValue }) => {
    try {
      const res = await USER_API.deleteUser(data.id);
      data.callback();
      return res;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getDetailUserAction = createAsyncThunk<any, UserDetailActionData>(
  'user_detail',
  async (data, { rejectWithValue }) => {
    try {
      const res = await USER_API.detailUser(data.id);
      return res;
    } catch (err: any) {
      data.callbackFailed();
      return rejectWithValue(err);
    }
  },
);

export const getTotalKindUserAction = createAsyncThunk<any>('userTotalKind', async () => {
  try {
    const res = await USER_API.getTotalKindUser({ application: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) });
    return res;
  } catch (err: any) {}
});

export const getListRolesAction = createAsyncThunk<any>('listRoles', async (data, { rejectWithValue }) => {
  try {
    const res = await USER_API.getRole();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});
