import { createAsyncThunk } from '@reduxjs/toolkit';
import KEY_PERMISSION from './api';

interface actionData {
  organization_application_id: any;
  callback?: () => void;
}

export const getListKeyPermissionAction = createAsyncThunk<any, actionData>(
  'getListKeyPermission',
  async (data, { rejectWithValue }) => {
    try {
      const response = await KEY_PERMISSION.getListKeyPermission({
        organization_application_id: data.organization_application_id,
      });
      data.callback?.();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
