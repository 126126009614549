import { createSlice } from '@reduxjs/toolkit';
import {
  // getPlatformUserAdmin,
  loginAction,
} from './auth.action';

const initialState: Auth.LoginState = {
  isLoading: false,
  error: null,
  meInfo: null,
  // listPlatform: { data: [], isLoading: false },
};

const { actions, reducer } = createSlice({
  name: 'auth_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // login
      .addCase(loginAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      })

      // get platform
      // .addCase(getPlatformUserAdmin.pending, (state, action) => {
      //   state.listPlatform.isLoading = true;
      // })
      // .addCase(getPlatformUserAdmin.fulfilled, (state, action) => {
      //   const localStorageData = [...action.payload];
      //   localStorage.setItem(LOCAL_STORAGE_KEY.LIST_PLATFORM, JSON.stringify(localStorageData));

      //   state.listPlatform.isLoading = false;
      //   state.listPlatform.data = action.payload;
      // })
      // .addCase(getPlatformUserAdmin.rejected, (state, action) => {
      //   state.listPlatform.isLoading = false;
      //   state.error = action.error;
      // });
  },
});

export { reducer };
export default actions;
