import HOME_API from '@/stores/screens/home/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

interface SendEmailActionData {
  dataSubmit: YourEmailData; // Replace with the actual type for YourEmailData
  callback: () => void;
}

interface YourEmailData {
  user_name: string;
  message: string;
  user_email: string;
}

export const sendEmailAction = createAsyncThunk<any, SendEmailActionData>(
  'create_user',
  async (data, { rejectWithValue }) => {
    try {
      const response = await HOME_API.sendEmail(data?.dataSubmit);
      data.callback();
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data || err?.name);
    }
  },
);
