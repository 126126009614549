const ROUTES = {
  ADMIN: {
    LOGIN: '/admin/login',
    HOME: '/admin',
  },
  DASHBOARD: {
    MAIN: '/admin/dashboard',
  },
  CATEGORY: {
    LIST: '/admin/category',
    CREATE: '/admin/category/create',
    DETAIL: '/admin/category/detail',
    EDIT: '/admin/category/edit',
  },
  USER: {
    LIST: '/admin/user',
    CREATE: '/admin/user/create',
    DETAIL: '/admin/user/detail',
    EDIT: '/admin/user/detail/edit',
    PERMISSIONS: {
      LIST: '/admin/user/permissions',
      CREATE: '/admin/user/permissions/create',
      DETAIL: '/admin/user/permissions/create',
      EDIT: '/admin/user/permissions/create',
    },
    ROLE_AND_PERMISSION: {
      LIST: '/admin/user/role-and-permissions',
    },
  },
  SETTINGS: {
    THEMES: {
      LIST: '/admin/settings/themes',
      CREATE: '/admin/settings/themes/create',
      DETAIL: '/admin/settings/themes/detail',
      EDIT: '/admin/settings/themes/edit',
    },
    PREFERENCES: '/admin/settings/preferences',
    PAGES: {
      LIST: '/admin/settings/pages',
      CREATE: '/admin/settings/pages/create',
      CLONE: '/admin/settings/pages/clone',
      EDIT: '/admin/settings/pages/edit',
    },
  },
  SELECT_APP: '/admin/select-app',
};

const KEY_ROUTES = {
  CATEGORY: 'category',
  DASHBOARD: 'dashboard',
  USER: 'user',
  SETTINGS: 'setting',
};

export { ROUTES, KEY_ROUTES };
