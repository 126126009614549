import { getDetailPageAction, getListPagesAction, getMasterPageAction } from '@/stores/screens/settings-page/action';
import { createSlice } from '@reduxjs/toolkit';

interface initialStateProps {
  error: any;
  listData: {
    data: any[];
    loading: boolean;
    total: number;
  };
  detail: {
    data: any;
    loading: boolean;
  };
  masterPage: {
    data: any;
    loading: boolean;
  };
  listSection: { data: any[] };
}

const initialState: initialStateProps = {
  error: null,
  listData: {
    data: [],
    total: 0,
    loading: false,
  },
  detail: {
    data: null,
    loading: false,
  },
  masterPage: {
    data: null,
    loading: false,
  },
  listSection: {
    data: [],
  },
};

const { actions, reducer } = createSlice({
  name: 'dashboard_slice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // getListPagesAction
      .addCase(getListPagesAction.pending, (state, action) => {
        state.listData.loading = true;
      })
      .addCase(getListPagesAction.fulfilled, (state, action) => {
        const { data, total } = action.payload;
        state.listData = {
          data: data.data,
          total,
          loading: false,
        };
      })
      .addCase(getListPagesAction.rejected, (state, action) => {
        state.listData.loading = false;
        state.error = action.error;
      })

      // getDetailPageAction
      .addCase(getDetailPageAction.pending, (state, action) => {
        state.detail.loading = true;
      })
      .addCase(getDetailPageAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.detail = {
          data,
          loading: false,
        };
      })
      .addCase(getDetailPageAction.rejected, (state, action) => {
        state.detail.loading = false;
        state.error = action.error;
      })

      // getMasterPageAction
      .addCase(getMasterPageAction.pending, (state, action) => {
        state.masterPage.loading = true;
      })
      .addCase(getMasterPageAction.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.masterPage = {
          data,
          loading: false,
        };
        const { ...newData } = data;
        state.listSection.data = newData.part.map(item => ({ id: item.id, label: item.name, value: item.slug }));
      })
      .addCase(getMasterPageAction.rejected, (state, action) => {
        state.masterPage.loading = false;
        state.error = action.error;
      });
  },
});

export { reducer };
export default actions;
