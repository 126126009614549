import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const USER_API = {
  getListUser: async () => {
    const response = await new AxiosClient().get('/admin/permission-group');
    return response;
  },
  getRole: async () => {
    const response = await new AxiosClient().get('/admin/roles');
    return response;
  },

  createPermisstion: async params => {
    const response = await new AxiosClient().post('/admin/permission', params, {
      headers: { 'Content-Type': 'application/json' },
    });
    const data = get(response, 'data', null);
    return data;
  },

  editPermisstion: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/permission/${id}/update`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  deletePermission: async (id: number | string) => {
    const response = await new AxiosClient().delete(`/admin/permission/${id}/delete`);
    return response;
  },
  // = = =

  changeRole: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/application-admin/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  changeStatus: async (id: number | string, data: any) => {
    const response = await new AxiosClient().put(`/admin/user/action/${id}`, data, {
      headers: { 'Content-Type': 'application/json' },
    });
    const rs = get(response, 'data', null);
    return rs;
  },

  detailUser: async (id: number | string) => {
    const response = await new AxiosClient().get(`/admin/user/${id}/application/detail`);
    const data = get(response, 'data', null);
    return data;
  },
};

export default USER_API;
