import { AxiosClient } from '@/configs/axios/axios';

const KEY_PERMISSION = {
  getListKeyPermission: async (params: any) => {
    const response = await new AxiosClient().get('admin/application-admin/permission', { params });
    return response;
  },
};

export default KEY_PERMISSION;
