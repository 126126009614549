import { get } from 'lodash';
import { AxiosClient } from '@/configs/axios/axios';

const AUTH_API = {
  loginAPI: async (params: Auth.LoginRequestData) => {
    const response = await new AxiosClient().post('/admin/login', params);
    const data = get(response, 'data', null);
    return data;
  },
  getPlatform: async () => {
    const response = await new AxiosClient().get('/admin/user/platform');
    return response;
  },
};

export default AUTH_API;
