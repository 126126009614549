import { SelectField } from '@/components/form';
import SpinLoading2 from '@/components/loading/SpinLoading2';
import { LOCAL_STORAGE_KEY, ROLE_LEVEL } from '@/constants';
import { KEY_ROUTES, ROUTES } from '@/constants/routes';
import useLoadingCommon from '@/hooks/useLoadingCommon';
import {
  CATEGORY_SUB_ROUTES,
  THEMES_SUB_2_ROUTES,
  THEMES_SUB_3_ROUTES,
  THEMES_SUB_ROUTES,
  USER_SUB_ROUTES,
} from '@/routers/_private';
import { useAppDispatch, useAppSelector } from '@/stores';
import { getListKeyPermissionAction } from '@/stores/screens/key-permission/action';
import { clearListKeyPermisstion } from '@/stores/screens/key-permission/reducer';
import {
  AlignLeftOutlined,
  ControlFilled,
  DashboardOutlined,
  DesktopOutlined,
  FundProjectionScreenOutlined,
  HddFilled,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Layout, Menu, Row, Typography } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import * as yup from 'yup';
import { PrivateLayoutStyle } from './PrivateLayout.style';

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const PrivateLayout: React.FC = () => {
  const outlet = useOutlet();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: dataListKey } = useAppSelector(state => state.keyPermission.listKeyPermission);
  const { isLoadingCommon } = useLoadingCommon();
  const [listPlatform, setListPlatform] = useState<any[]>([]);
  const MENU = [
    {
      key: KEY_ROUTES.DASHBOARD,
      icon: <DashboardOutlined />,
      label: <Link to={ROUTES.DASHBOARD.MAIN}>Dashboard</Link>,
      path: ROUTES.DASHBOARD.MAIN,
    },
    {
      key: KEY_ROUTES.CATEGORY,
      icon: <AlignLeftOutlined />,
      label: <Link to={ROUTES.CATEGORY.LIST}>Category</Link>,
      path: ROUTES.CATEGORY.LIST,
    },
    {
      key: KEY_ROUTES.USER,
      icon: <TeamOutlined />,
      label: <>User Management</>,
      children: [
        {
          key: 'user_sub_1',
          icon: <UserOutlined />,
          label: <Link to={ROUTES.USER.LIST}>User</Link>,
          path: ROUTES.USER.LIST,
        },
        {
          key: 'user_sub_2',
          icon: <HddFilled />,
          label: <Link to={ROUTES.USER.PERMISSIONS.LIST}>User Permisson</Link>,
          path: ROUTES.USER.PERMISSIONS.LIST,
        },
        Number(localStorage.getItem(LOCAL_STORAGE_KEY.ROLE_ADMIN)) === ROLE_LEVEL.SUPER_ADMIN && {
          key: 'user_sub_3',
          icon: <ControlFilled />,
          label: <Link to={ROUTES.USER.ROLE_AND_PERMISSION.LIST}>Role & Permisson</Link>,
          path: ROUTES.USER.ROLE_AND_PERMISSION.LIST,
        },
      ],
    },
    {
      key: KEY_ROUTES.SETTINGS,
      icon: <SettingOutlined />,
      label: <>Settings</>,
      children: [
        {
          key: 'themes_sub_1',
          icon: <DesktopOutlined />,
          label: <Link to={ROUTES.SETTINGS.THEMES.LIST}>Themes</Link>,
          path: ROUTES.SETTINGS.THEMES.LIST,
        },
        {
          key: 'themes_sub_3',
          icon: <FundProjectionScreenOutlined />,
          label: <Link to={ROUTES.SETTINGS.PAGES.LIST}>Pages</Link>,
          path: ROUTES.SETTINGS.PAGES.LIST,
        },
        // {
        //   key: 'themes_sub_2',
        //   icon: <AlignLeftOutlined />,
        //   label: <Link to="/admin/settings/preferences">Preferences</Link>,
        //   path: '/admin/settings/preferences',
        // },
      ],
    },
  ];

  const [openKeys, setOpenKeys] = useState<any>([]);

  const selectedKeys = useMemo(() => {
    const pathname = location.pathname;

    const foundItem = MENU.find(item => pathname?.includes(item.key));

    const funcCheckUrl = () => {
      if (foundItem) {
        setOpenKeys([foundItem.key]);
        const funcCallback = item => {
          if (item.path === pathname) {
            return item.key;
          } else if (item.children) {
            for (let i = 0; i < item.children.length; i++) {
              const x = item.children[i];
              const result = funcCallback(x);
              if (result) {
                setOpenKeys(prev => [...prev, x.key]);
                return result;
              }
            }
          }
        };
        return funcCallback(foundItem);
      } else {
        return MENU.find(item => item.path === pathname)?.key;
      }
    };

    let key: any;
    if (!funcCheckUrl()) {
      const pathnameParts = pathname.split('/');
      const newPathname1 = pathnameParts.join('/');
      const newPathname2 = pathnameParts.slice(0, -1).join('/');

      if (USER_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'user_sub_1';
        setOpenKeys([KEY_ROUTES.USER]);
      } else if (USER_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'user_sub_2';
        setOpenKeys([KEY_ROUTES.USER]);
      } else if (THEMES_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'themes_sub_1';
        setOpenKeys(KEY_ROUTES.SETTINGS);
      } else if (THEMES_SUB_2_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'themes_sub_2';
        setOpenKeys(KEY_ROUTES.SETTINGS);
      } else if (THEMES_SUB_3_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'themes_sub_3';
        setOpenKeys(KEY_ROUTES.SETTINGS);
      } else if (CATEGORY_SUB_ROUTES.find(item => item === newPathname1 || item === newPathname2)) {
        key = 'category';
        setOpenKeys(KEY_ROUTES.CATEGORY);
      }
    } else key = funcCheckUrl();

    return key;
  }, [location.pathname]);

  const validationSchema = yup.object().shape({
    app_id: yup.string().required('app_id REQUIRED'),
  });

  const initialValues = {
    app_id: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) ?? '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: () => {},
  });

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY.LIST_PLATFORM)) {
      setListPlatform(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.LIST_PLATFORM) ?? ''));
    }
  }, []);

  /**
   * TODO
   * call api get key permission once or the brower refresh
   */
  useEffect(() => {
    dataListKey?.length === 0 &&
      localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) &&
      dispatch(
        getListKeyPermissionAction({
          organization_application_id: localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID),
        }),
      );
  }, [location.pathname]);

  return (
    <PrivateLayoutStyle>
      {isLoadingCommon && <SpinLoading2 />}
      <Sider className="layout-sider" breakpoint="lg" collapsedWidth="0" onBreakpoint={_ => {}} onCollapse={_ => {}}>
        <div className="logo" style={{ fontWeight: 700, fontSize: 16 }}>
          {/* <Image preview={false} alt="logo" src={LOGO_IMAGE} className="logo-img" />
           */}
          Matching Platform Admin
        </div>
        {selectedKeys && (
          <Menu
            className="layout-menu"
            mode="inline"
            defaultOpenKeys={openKeys}
            defaultSelectedKeys={[selectedKeys]}
            items={MENU}
          />
        )}
      </Sider>
      <Layout className="layout-body">
        <Header className="body-header">
          <Row>
            <Col span={8} className="body-header-title"></Col>
            <Col span={14} className="select-form-container">
              <Form name="login" className="select-form" onFinish={() => formik.handleSubmit()}>
                <Text style={{ marginRight: 10 }}>Select app</Text>
                <SelectField
                  field={formik.getFieldProps('app_id')}
                  setFieldValue={(field, value) => {
                    localStorage.setItem(LOCAL_STORAGE_KEY.APP_ID, value);
                    const name = listPlatform.find(item => item.value === value)?.name ?? '';
                    localStorage.setItem(LOCAL_STORAGE_KEY.APP_NAME, name);
                    const role_admin = listPlatform.find(item => item.value === value).role.level;
                    localStorage.setItem(LOCAL_STORAGE_KEY.ROLE_ADMIN, role_admin);
                    if (location?.pathname?.includes(ROUTES.USER.PERMISSIONS.LIST)) {
                      window.location.href = ROUTES.USER.PERMISSIONS.LIST;
                    } else if (location?.pathname?.includes(ROUTES.USER.LIST)) {
                      window.location.href = ROUTES.USER.LIST;
                    } else {
                      window.location.reload();
                    }
                  }}
                  error={formik.errors.app_id}
                  touched={formik.touched.app_id}
                  selectProps={{
                    placeholder: 'Select an application',
                    getPopupContainer: (triggerNode: HTMLElement) => triggerNode?.parentNode as HTMLElement,
                  }}
                  labelAlign="left"
                  optionsSelect={listPlatform}
                  className="select-style-2"
                />

                {/* <Button className="btn-submit" htmlType="submit" type="primary">
                  Submit
                </Button> */}
              </Form>
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  localStorage.clear();
                  dispatch(clearListKeyPermisstion());
                  navigate(ROUTES.ADMIN.LOGIN);
                }}
              >
                {t('global.logout')}
              </Button>
            </Col>
          </Row>
        </Header>

        <Content className={`body-content ${location.pathname === ROUTES.DASHBOARD.MAIN && 'dashboard-screen'}`}>
          {/* able use */}
          {/* {dataListKey?.length > 0 && outlet} */}
          {outlet}
        </Content>

        <Footer className="body-footer">Matching Platform</Footer>
      </Layout>
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;
