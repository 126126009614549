import { ButtonProps, Button as CButton } from 'antd';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
type TypeButton = 'primary' | 'link' | 'text' | 'ghost' | 'default' | 'dashed';

const ButtonStyled = styled(CButton)<any>`
  font-weight: 500;
`;

interface CButtonProps extends ButtonProps {
  children?: ReactNode;
  props?: any;
  type?: TypeButton;
  [key: string]: any;
}

const Button: FC<CButtonProps> = ({ children, type = 'default', ...props }) => {
  return (
    <ButtonStyled type={type} background={type} {...props}>
      {children}
    </ButtonStyled>
  );
};
export default Button;
