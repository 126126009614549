import { Spin } from 'antd';
import React from 'react';
import styled from 'styled-components';

const SpinLoading2: React.FC = () => {
  return (
    <SpinLoading2Style>
      <Spin spinning={true} />
    </SpinLoading2Style>
  );
};

export default SpinLoading2;

const SpinLoading2Style = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.5);
`;
