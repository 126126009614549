import { createAsyncThunk } from '@reduxjs/toolkit';

import TIME_VOTING_API from './voting-timeapi';
export const getTimeVotingAction = createAsyncThunk<any>('score', async (_, { rejectWithValue }) => {
  try {
    const res = await TIME_VOTING_API.getTimeVoting();
    return res;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const updateTimeVotingAction = createAsyncThunk<any, any>('update-score', async (data, { rejectWithValue }) => {
  try {
    const response = await TIME_VOTING_API.updateTimeVoting(data);
    window.location.reload();
    return response;
  } catch (err: any) {
    return rejectWithValue(err?.response?.data || err?.name);
  }
});
