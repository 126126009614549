import { createAsyncThunk } from '@reduxjs/toolkit';
import AUTH_API from './auth.api';
import { LOCAL_STORAGE_KEY } from '@/constants';

export const loginAction = createAsyncThunk<any, Auth.LoginRequestPayload>(
  'auth',
  async ({ data, callback = () => {} }, { rejectWithValue }) => {
    try {
      const user = await AUTH_API.loginAPI(data);
      if (user?.token) {
        localStorage.setItem(LOCAL_STORAGE_KEY.TOKEN, user.token);
        localStorage.setItem(LOCAL_STORAGE_KEY.USER_ID, user.user_info.id);
        localStorage.setItem(LOCAL_STORAGE_KEY.ROLE_ADMIN, user.user_info.role);
        const transformedData = user.user_info.application_admins.map((item: any) => ({
          value: item.id,
          name: item.application_type,
          label: `${item.application_type.charAt(0).toUpperCase()}${item.application_type.slice(1)}`,
          role: item.role,
        }));
        localStorage.setItem(LOCAL_STORAGE_KEY.LIST_PLATFORM, JSON.stringify(transformedData));

        callback();
      }
      return user;
    } catch (err: any) {
      rejectWithValue(err?.data || err?.name);
    }
  },
);

// export const getPlatformUserAdmin = createAsyncThunk<any>('getPlatfrom', async (any, { rejectWithValue }) => {
//   try {
//     const response = await AUTH_API.getPlatform();
//     const transformedData = response.data.map((item: any) => ({
//       value: item.id,
//       name: item.application_type,
//       label: `${item.application_type.charAt(0).toUpperCase()}${item.application_type.slice(1)}`,
//     }));

//     return transformedData;
//   } catch (err: any) {
//     rejectWithValue(err?.data || err?.name);
//   }
// });
